import React, { Component } from 'react';
import Navbar from './NavbarVerify';
import { Button, Col, Row } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import axios from 'axios';
import { Redirect } from "react-router-dom";
import LoaderRounded from "../LoaderRounded";
import RejectCollegeIDCardPopup from "../VoterVerification/VoterVerifyPopup";
import BackAndBreadCumOneParent from '../BackAndBreadCumOneParent';
import * as XLSX from "xlsx";
import ReactPaginate from 'react-paginate';
import LazyImage from './LazyImage';
export default class VoterVerifyReApprove extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      name: "",
      yinId: "",
      firstName: "",
      lastName: "",
      modalInputName: "",
      mobileNumber: "",
      showModalPopup: false,
      buttonFalse: false,
      showSecondmodel: false,
      isShowSecondModelPopupClose: false,
      redirect: false,
      loader: false,
      userData: [],
      userData1: [],
      userCount: "",
      inProcessCount: 0,
      approvedCount: 0,
      rejectedCount: 0,
      showVoteDisputetpop: false,
      isDisputed: false,
      disabled: false,
      first_name: "Buddy",
      errorCandidate: "",
      last_name: "",
      start_time: "",
      end_time: "",
      deletedIndex: 0,
      rejectReason: [],
      disputedBY: "",
      selectedDate: "",
      approvedIndex: 0,
      CityData: [],
      CollageData: [],
      districtsLocal: JSON.parse(localStorage.getItem("districts")),
      selected_city: "",
      selectedStatus: "",
      college_code: "",
      filterString: "",
      limit: 1000,
      skip: 0,
      page_selected: 0,
      allpage: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.storeRejectIDCardCandidateYinID =
      this.storeRejectIDCardCandidateYinID.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.SetCollegeIDCardReason = this.SetCollegeIDCardReason.bind(this);
    this.selectDate = this.selectDate.bind(this);
    this.getMobileNumber = this.getMobileNumber.bind(this);
    this.getCandidateListDate = this.getCandidateListDate.bind(this);
    this.approvedVotes = this.approvedVotes.bind(this);
    this.getCandidateWithCity = this.getCandidateWithCity.bind(this);
    this.getCandidateWithCollege = this.getCandidateWithCollege.bind(this);
    this.getCandidateListByMobileNumber = this.getCandidateListByMobileNumber.bind(this);
    this.handleStatusOnChange = this.handleStatusOnChange.bind(this);
    this.log = this.log.bind(this);
    this.backFunctionality = this.backFunctionality.bind(this);
    this.searchList = this.searchList.bind(this);
    this.searchFromList = this.searchFromList.bind(this);
    this.voterIdCardVerified = this.voterIdCardVerified.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    let operator = JSON.parse(localStorage.getItem("operator"))
    let operatorEmailAddress = "";
    try {
      operatorEmailAddress = operator.email;
      this.setState({ operatorEmailAddress, isLoading: false });
    } catch (error) {
      console.error(error);
    }
    this.showCity();

    const storedDistrict = localStorage.getItem("searchedDistrictRe");
    const storedCollege = localStorage.getItem("searchedCollegeRe");
    const storedStatus = localStorage.getItem("searchedStatus");

    this.showCollage(storedDistrict ? storedDistrict : "");
    if (storedDistrict || storedStatus || storedCollege) {
      this.setState({ selected_city: storedDistrict, selectedStatus: storedStatus, college_code: storedCollege })
    }

    this.getCandidateList(
      storedDistrict ? storedDistrict : this.state.selected_city,
      storedStatus ? storedStatus : this.state.selectedStatus,
      storedCollege ? storedCollege : this.state.college_code,
      this.state.limit,
      this.state.skip
    );
  }

  handlePageChange(page) {
    let val = page.selected === 0 ? 0 : page.selected * this.state.limit;
    this.setState({
      skip: val,
      page_selected: page.selected,
    });

    this.getCandidateList(
      this.state.selected_city,
      this.state.selectedStatus,
      this.state.college_code,
      this.state.limit,
      val
    );
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  // district onChange
  getCandidateWithCity(event) {
    this.showCollage(event.target.value);
    let selected_city = event.target.value === "*" ? "*" : event.target.value;
    let selectedCollegeCode = event.target.value === "*" ? this.state.college_code === " " : this.state.college_code;

    localStorage.setItem("searchedDistrictRe", selected_city);
    if (event.target.value === "*") {
      localStorage.removeItem("searchedDistrictRe");
      localStorage.removeItem("searchedCollegeRe");
    }
    this.setState({ selected_city: selected_city, college_code: "", });
    this.getCandidateList(selected_city, this.state.selectedStatus, selectedCollegeCode, this.state.limit, this.state.skip);
  }

  // city onChange
  getCandidateWithCollege(event) {
    const selectedCollegeCode = event.target.value;

    this.setState(
      { college_code: selectedCollegeCode },
      () => {
        localStorage.setItem("searchedCollegeRe", selectedCollegeCode);
        this.showCity();
        this.getCandidateList(
          this.state.selected_city,
          this.state.selectedStatus,
          selectedCollegeCode,
          this.state.limit,
          this.state.skip
        );
      }
    );

    // The rest of your code remains unchanged...
    let operator = JSON.parse(localStorage.getItem("operator"));
    let operatorEmailAddress = "";
    try {
      operatorEmailAddress = operator.email;
      this.setState({ operatorEmailAddress, isLoading: false });
    } catch (error) {
      console.error(error);
    }

    const storedDistrict = localStorage.getItem("searchedDistrictRe");

    if (storedDistrict) {
      this.setState({ selected_city: storedDistrict })
    }

    this.getCandidateList(
      storedDistrict ? storedDistrict : this.state.selected_city,
      this.state.selectedStatus,
      selectedCollegeCode,
      this.state.limit,
      this.state.skip
    );
  }

  // Status onChange
  handleStatusOnChange(e) {
    this.setState({
      selectedStatus: e.target.value,
    });
    localStorage.setItem("searchedStatus", e.target.value);
    this.getCandidateList(
      this.state.selected_city,
      e.target.value,
      this.state.college_code,
      this.state.limit,
      this.state.skip
    );
  }

  // Year dropdown
  componentDidMount() {
    const currentYear = new Date().getFullYear();
    const initialYears = this.generateYears(2021, currentYear);
    this.setState({
      years: initialYears.concat(currentYear + 1),
    });
  }

  generateYears(start, end) {
    const yearsArray = [];
    for (let year = end; year >= start; year--) {
      yearsArray.push(year);
    }
    return yearsArray;
  }

  handleCompleteYear = () => {
    const currentYear = new Date().getFullYear();
    this.setState((prevState) => ({
      years: [...prevState.years, currentYear + 1],
    }));
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  // get candidate data
  getCandidateList(selected_city, selected_status, college_code, limit, skip) {
    this.setState({
      loader: true,
    });

    axios.get(process.env.REACT_APP_APIURL +
      `/coordinator/user/college_id/unapproved/idcard/sort?cities=${selected_city ? selected_city : "*"
      }&status=${selected_status ? selected_status : ""}&limit=${limit}&college_code=${college_code ? college_code : ""}&skip=${skip}`
    )
      .then((response) => {
        if (response.data.data) {
          let con = Math.ceil(
            parseInt(response?.data?.count) / this.state.limit
          );

          this.setState({
            userData: response?.data?.data,
            userData1: response?.data?.data,
            userCount: response?.data?.count,
            inProcessCount: response?.data?.inProcessCount,
            approvedCount: response?.data?.approvedCount,
            rejectedCount: response?.data?.rejectedCount,
            loader: false,
            college_code: college_code,
            allpage: con
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          noDisputeList: true,
          buttonFalse: true,
        });
      });
  }

  // get candidate list by date
  getCandidateListDate() {
    this.setState({
      loader: true,
    });
    if (this.state.selectedDate === "") {
      alert("Please select date and time");
    } else {
      let dateData = {
        selectedDate: this.state.selectedDate,
      };
      axios.post(process.env.REACT_APP_APIURL + `/coordinator/user/college_id/unapproved/idcard/date`, dateData)
        .then((response) => {
          this.setState({
            userData: response?.data?.data,
            userData1: response?.data?.data,
            userCount: response?.data?.count,
            approvedCount: response?.data?.approvedCount,
            rejectedCount: response?.data?.rejectedCount,
            loader: false,
          });
        })
        .catch((error) => {
          this.setState({
            loader: false,
            noDisputeList: true,
            buttonFalse: true,
          });
        });
    }
  }

  // get candidate list by mobile number
  getCandidateListByMobileNumber() {
    this.setState({
      loader: true,
    });
    if (this.state.mobileNumber === "") {
      alert("Please enter mobile number");
    } else {
      let dateData = {
        mobile: this.state.mobileNumber,
      };
      axios.post(process.env.REACT_APP_APIURL + `/coordinator/user/college_id/unapproved/idcard/mobile`, dateData)
        .then((response) => {
          this.setState({
            userData: response?.data?.data,
            userData1: response?.data?.data,
            userCount: response?.data?.count,
            approvedCount: response?.data?.approvedCount,
            rejectedCount: response?.data?.rejectedCount,
            loader: false,
          });
        })
        .catch((error) => {
          this.setState({
            loader: false,
            noDisputeList: true,
            buttonFalse: true,
          });
        });
    }
  }

  storeRejectIDCardCandidateYinID(yinID) {
    localStorage.setItem("dispute_candidate_id", yinID);
  }

  isShowPopup = (status, yin_id, indexSelected) => {
    this.setState({
      showModalPopup: true,
      disabled: true,
      yinId: yin_id,
      deletedIndex: indexSelected,
    });
  };

  isClosePopup = () => {
    this.setState({ showModalPopup: false });
  };

  isShowVoteDisputetpop = (status) => {
    this.setState({ showVoteDisputetpop: true });
  };

  closeConfirmModal = () => {
    this.setState({ showModalPopup: false });
  };

  disableButton = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  // reject candidate list
  handleSubmit() {
    this.setState({
      showModalPopup: false,
    });
    let nominateUserId = {
      yin_id: this.state.yinId,
      verification_status: false,
      verification_reject_reason: this.state.rejectReason,
    };
    axios.post(process.env.REACT_APP_APIURL + `/coordinator/user/college_id/verified_status/reject`, nominateUserId)
      .then((response) => {
        let datas = this.state.userData?.filter(
          (e, i) => i !== this.state.deletedIndex
        );
        this.setState({ userData: datas });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Approved candidate list
  approvedVotes(yin_id, approvedIndex) {
    this.setState({
      showModalPopup: false,
    });
    let nominateUserId = {
      yin_id: yin_id,
      verification_status: true,
    };
    axios.put(process.env.REACT_APP_APIURL + `/coordinator/user/college_id/verified_status/approved`, nominateUserId)
      .then((response) => {
        let datas = this.state.userData?.filter((e, i) => i !== approvedIndex);
        this.setState({ userData: datas });
        this.log("IMAGE_VERIFIED", yin_id, "VERIFIED");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  SetCollegeIDCardReason = async (rejectReasonConfirm) => {
    if (this.state.rejectReason.includes(rejectReasonConfirm)) {
    } else {
      this.state.rejectReason.push(rejectReasonConfirm);
    }
  };

  selectDate(event) {
    this.setState({
      selectedDate: event.target.value,
    });
  }

  getMobileNumber(event) {
    this.setState({
      mobileNumber: event.target.value,
    });
  }

  async log(type, user, id) {
    let operatorData = JSON.parse(localStorage.getItem("operator"));
    let operator = operatorData.email;
    let params = { type, user, id, operator };
    try {
      await axios.post(process.env.REACT_APP_APIURL + `/audit/ops/log`, params);
    } catch (error) {
      console.log("failed to log", JSON.stringify(params));
    }
  }

  // get district list
  showCity() {
    let districts = JSON.parse(localStorage.getItem("districts"));
    if (districts && districts[0] !== "*") {
      this.setState({
        CityData: districts,
      });
    } else {
      axios.get(process.env.REACT_APP_APIURL + `/address/city/MH`)
        .then((response) => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.setState({
              CityData: response.data,
            });
          }
        })
        .catch((error) => {
          console.log("error in showCity", error);
        });
    }
  }

  // get college list
  showCollage(city) {
    let citydata = city;
    axios.get(process.env.REACT_APP_APIURL + `/college/district/${citydata}`)
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.setState({
            CollageData: response.data,
          });
        } else {
          this.setState({
            CollageData: [],
          });
        }
      })
      .catch((error) => {
        console.log("error in showCity", error);
      });
  }

  // back button functionality
  backFunctionality() {
    this.setState({
      redirect: true,
    });
  }

  // global search functionality
  searchFromList(event) {
    this.setState({
      candidate: event.target.value,
      currentFilterBy: event.target.name,
    });
  }

  searchList(char) {
    let f = this.state.userData1?.filter(
      (item) =>
        item?.first_name
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.last_name
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.mobile
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.college_name
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.isVoterIdCardStatus
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase())
    );
    this.setState({
      userData: f,
    });
  }

  // voter ID card verified
  voterIdCardVerified(collegeCode) {
    const storedDistrict = localStorage.getItem("searchedDistrictRe");
    if (storedDistrict) {
      this.setState({ selected_city: storedDistrict })
    }
    let collegeCodess = collegeCode ? collegeCode : this.state.college_code;

    let nominateUserId = {
      collegeCodes: [collegeCodess]
    };

    axios.post(process.env.REACT_APP_APIURL + `/college/active/voterlist/fordownload`, nominateUserId)
      .then((response) => {
        this.log("VERIFIED_VOTER_LIST_FOR_COLLEGE", collegeCodess, "VERIFIED");
        this.getCandidateList(
          storedDistrict ? storedDistrict : this.state.selected_city,
          this.state.selectedStatus,
          this.state.college_code,
          this.state.limit,
          this.state.skip
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    let cities;
    if (this.state.districtsLocal && this.state.districtsLocal[0] !== "*") {
      cities = this.state?.CityData?.map((e, key) => {
        return (
          <option key={key} value={e}>
            {e}
          </option>
        );
      });
    } else {
      cities = this.state?.CityData?.map((e, key) => {
        return (
          <option key={key} value={e.name}>
            {e.name}
          </option>
        );
      });
    }
    let CollegeList;
    CollegeList = this.state.CollageData?.map((e, key) => {
      return (
        <option key={key} value={e.college_code}>
          {e.college_name}
        </option>
      );
    });

    // get users district data
    const getDistData = localStorage.getItem("districts");
    const districtData = JSON.parse(getDistData);
    const getNewDistData = districtData?.map((itm, index) => {
      return itm;
    });

    if (this.state.redirect) {
      return (
        <Redirect to={{
          pathname: "/voter/verify"
        }}
        />
      );
    }

    // Excel export
    const downloadExcel = (customHeadings) => {
      let dataSet = [];
      dataSet = customHeadings;
      const worksheet = XLSX.utils.json_to_sheet(dataSet);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, this.state.userData[0].college_name + " " + "Re-Approve_VoterIDCardList.xlsx");
      // setSheetLoader(false);
    };

    const CustomExcel = () => {
      // setSheetLoader(true);
      const customHeadings =
        this.state.userData !== undefined && this.state.userData.length > 0
          ? this.state.userData.map((data, index) => ({
            Id: index + 1,
            Name: data.first_name + " " + data.last_name ?? "-",
            College_Name: data.college_name ?? "-",
            VoterIdCardStatus: data.isVoterIdCardStatus ?? "-"
          }))
          : "";
      downloadExcel(customHeadings);
    };

    return (
      <>
        <div className="topbar">
          <Navbar />
        </div>

        <div style={{ paddingLeft: "100px", display: "flex", marginTop: "25px", }}>
          <button onClick={() => this.backFunctionality()} className='btn btn-md backbuttn'
            style={{ color: "#0D6EFD", backgroundColor: "white", borderRadius: "10px", marginRight: "350px" }}>
            <Icon.ArrowLeft /> Back
          </button>

          <h3 style={{ fontWeight: "600", textAlign: "center" }}>
            Re-Approve Voter ID Card List
          </h3>
        </div>

        <div className="container">
          {/* <div className="mt-3 "> */}
          <div className="d-flex mt-3 mb-3 justify-content-end">
            <h6 className="mt-2">Inprocess: </h6>
            <h6
              style={{
                display: "flex",
                borderRadius: "80px",
                padding: "10px",
                backgroundColor: "#FFFB00",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
              }}
            >
              {this.state.inProcessCount}
            </h6>

            <h6 className="mt-2 mr-2"> Approved:</h6>
            <h6
              style={{
                display: "flex",
                borderRadius: "80px",
                padding: "10px",
                backgroundColor: "#FFFB00",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              {this.state.approvedCount}
            </h6>

            <h6 className="mt-2 mr-2"> Rejected: </h6>
            <h6
              style={{
                display: "flex",
                borderRadius: "80px",
                padding: "10px",
                backgroundColor: "#FFFB00",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {this.state.rejectedCount}
            </h6>
          </div>
          {/* </div> */}

          <div className="mx-auto border">
            <Row md={12} className="mx-4 mx-sm-0 mt-2 mb-2">
              <Col md={12} className="d-flex">
                <Row md={12}>
                  <Col md={3} className="mb-2 sm-mb-2">
                    <label>Select by District</label>
                    <select
                      type="button"
                      name="city"
                      value={this.state.selected_city}
                      className="btn dropdown-toggle w-100"
                      style={{ backgroundColor: "#003147", color: "white" }}
                      onChange={this.getCandidateWithCity}
                      id="district"
                    >
                      <option value={getNewDistData}>All</option>
                      {cities}
                    </select>
                  </Col>

                  <Col md={3} className="mb-2 sm-mb-2">
                    <label>Select by Collage</label>
                    <select
                      type="button"
                      name="city"
                      value={this.state.college_code}
                      className="btn dropdown-toggle w-100"
                      style={{ backgroundColor: "#003147", color: "white" }}
                      onChange={this.getCandidateWithCollege}
                      id="district"
                    >
                      <option value={""}>All Collage</option>
                      {CollegeList}
                    </select>
                  </Col>

                  <Col md={3} className="mb-2 sm-mb-2">
                    <label htmlFor="mobileNumber">Search by Status</label>
                    <select
                      onChange={this.handleStatusOnChange}
                      value={this.state.selectedStatus}
                      name="selectedStatus"
                      type="button"
                      className="btn dropdown-toggle w-100"
                      style={{ backgroundColor: "#003147", color: "white" }}
                    >
                      <option value="INPROCESS">INPROCESS</option>
                      <option value="APPROVED">APPROVED</option>
                      <option value="REJECTED">REJECTED</option>
                    </select>
                  </Col>

                  <Col md={3} className="mb-2 sm-mb-2">
                    <label htmlFor="selectDate">Select Date For list</label>
                    <div className="d-flex">
                      <input
                        type="Date"
                        id="selectDate"
                        className="form-control me-2 w-100"
                        name="date"
                        onChange={this.selectDate}
                      />
                      <Button
                        onClick={this.getCandidateListDate}
                        style={{ backgroundColor: "#003147", color: "white" }}
                      >
                        <Icon.Search />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={12} className="d-flex">
                <Col md={3} className="mb-2 sm-mb-2">
                  <label htmlFor="mobileNumber">Enter mobile number</label>
                  <div className="d-flex">
                    <input
                      type="number"
                      id="mobileNumber"
                      className="form-control me-2 w-100"
                      name="mobileNumber"
                      onChange={this.getMobileNumber}
                    />
                    <Button
                      onClick={this.getCandidateListByMobileNumber}
                      style={{ backgroundColor: "#003147", color: "white" }}
                    >
                      <Icon.Search />
                    </Button>
                  </div>
                </Col>

                <Col md={3} className="mb-2 sm-mb-2 m-3 mt-4">
                  <input
                    type="text"
                    placeholder="Search here"
                    name="search"
                    value={this.state.filterString}
                    onChange={(e) => {
                      this.setState({ filterString: e.target.value });
                      this.searchList(e.target.value);
                    }}
                    className="form-control"
                  ></input>
                </Col>

              </Col>
            </Row>
          </div>

          {this.state.college_code?.length === 0 ? "" : this.state.userData?.length === 0 ? "" : (
            <Row className="mx-1">
              <Col md={12} className='mt-4'>
                <div className='d-flex justify-content-end'>
                  <Button onClick={() => CustomExcel()} className='excelBtn'>
                    <Icon.BoxArrowDown className='me-2' />Export
                  </Button>
                </div>
              </Col>
            </Row>
          )}

          {this.state.loader ? (
            <LoaderRounded />
          ) : this.state.userData.length > 0 ? (
            <div className="row mt-2">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="table-responsive-md">
                  <table className="table table-bordered table-responsive table-hover">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Profile Image </th>
                        <th>Name</th>
                        <th>College Name</th>
                        <th>College ID Card</th>
                        <th>Government ID Card</th>
                        <th>Approve / Reject</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.loader ? <LoaderRounded /> :
                          this.state.userData !== undefined && this.state.userData !== null && this.state.userData.length !== 0 ?
                            this.state.userData.map((user, id) => (
                              <tr key={id}>
                                <td>{this.state.page_selected * this.state.limit + id + 1}</td>
                                <td>
                                  {/* <img src={user?.profile_image} style={{ width: "50px", height: "50px", borderRadius: "30px" }} alt="profile image" loading="lazy" /> */}
                                
                                        <LazyImage  src={user?.profile_image }
                                          alt="College ID Card"
                                          style={{
                                            maxWidth: '50px',
                                            maxHeight: '50px',
                                            objectFit: 'contain',
                                            borderRadius: '12px',
                                          }}
                                      onClick={() => window.open(user?.profile_image, '_blank')}/>
                                    </td>

                                <td>{user?.first_name + " " + user?.last_name}<br />
                                  {user?.mobile}<br />
                                  <b>{user?.user_type}</b><br />
                                  <p className="btn btn-sm border" style={{ fontWeight: "700" }}>
                                    {user?.isVoterIdCardStatus}
                                  </p>

                                </td>
                                <td>{user?.college_name}</td>
                                <td>
                                  <div className='text-center'>
                                    {/* <img src={user?.college_id} style={{ width: "200px", height: "200px", borderRadius: "10px" }}
                                      onClick={() => window.open(user?.college_id, '_blank')} alt="college id card" loading="lazy" /> */}
                                       
                                       <LazyImage  src={user?.college_id }
                                      alt="College ID Card"
                                      style={{
                                        maxWidth: '200px',
                                        maxHeight: '200px',
                                        objectFit: 'contain',
                                        borderRadius: '10px',
                                      }}
                                      onClick={() => window.open(user?.college_id, '_blank')}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className='text-center'>
                                    {/* <img src={user?.government_id} style={{ width: "200px", height: "200px", borderRadius: "10px" }}
                                      onClick={() => window.open(user?.government_id, '_blank')} alt="government id card" loading="lazy" /> */}

                            <LazyImage
                              src={user?.government_id}
                                  alt="College ID Card"
                                  style={{
                                    maxWidth: '200px',
                                    maxHeight: '200px',
                                    objectFit: 'contain',
                                    borderRadius: '12px',
                                  }}
                                  onClick={() => window.open(user?.government_id, '_blank')}
                                />
                                  </div>
                                </td>
                                <td>
                                  <div className='d-flex'>
                                    <button onClick={() => this.isShowPopup(true, user.yin_id, id)}
                                      disabled={this.state.buttonFalse}
                                      type="button"
                                      id="btndispute"
                                      className="btn btn-sm rounded-pill Disputebtn border me-2"
                                    >
                                      Reject
                                    </button>
                                    {/* <button onClick={() => this.approvedVotes(user.yin_id, index)}
                                                disabled={this.state.buttonFalse}
                                                type="button"
                                                id="btndispute"
                                                className="btn btn-sm rounded-pill DisputeApproved border"
                                                >
                                                Verified
                                            </button> */}
                                  </div>
                                </td>
                              </tr>
                            )) : "No Data Found"
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12" style={{ textAlign: "center", marginTop: "20px" }}>
                {this.state.college_code ?
                  <button onClick={() => this.voterIdCardVerified(this.state.college_code)}
                    disabled={this.state.buttonFalse}
                    type="button"
                    id="btndispute"
                    className="btn btn-sm rounded-pill DisputeApproved border"
                  >
                    All Verified
                  </button> : ""}
              </div>
            </div>
          ) : (
            <h5 style={{ textAlign: "center", marginTop: "20px" }}>
              No Record Found...
            </h5>
          )}
        </div>

        <div className='' style={{ margin: "30px 0px 10px 100px" }} >
          <ReactPaginate
            nextLabel="Next"
            previousLabel="Previous"
            pageCount={this.state.allpage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            containerClassName="pagination"
            activeClassName="btn btn-primary"
            pageClassName="btn"
            previousClassName="btn"
            nextClassName="btn"
            onPageChange={this.handlePageChange}
          />
        </div>

        <RejectCollegeIDCardPopup
          showModalPopup={this.state.showModalPopup}
          closeModal={this.closeConfirmModal}
          onPopupCloseMain={this.isClosePopup.bind(this)}
          handleSubmit={this.handleSubmit}
          rejectCollegeIdCardReason={this.SetCollegeIDCardReason}
        ></RejectCollegeIDCardPopup>
      </>
    );
  }
}

