import React, { Component } from "react";
import axios from "axios";
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import S3 from "react-aws-s3";
import Compressor from 'compressorjs';
import logo from "../Assets/images/avatar1.png";
import { uploadImageFromBackend } from "../Utils/uploadImage";

export default class AssignEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: '',
            yin_id: '',
            receipt_Photo: '',
            payment_type: 'OFFLINE',
            changeToView: false,
            memberList: [],
            receiptNumber: '',
            receipt_number_error: '',
            receipt_photo_error: '',
            receipt_photo_errors: '',
            disabledd: false,
            event_title: "",
            event_id: false,
            eventlist: [],
            eventid: "",
            errors: {},
            event: JSON.parse(localStorage.getItem('selected_event')),

        };
        this.handleFormChange = this.handleFormChange.bind(this);
        this.uploadImageToS3 = this.uploadImageToS3.bind(this);
        this.pdfData = this.pdfData.bind(this);

        this.onSelectforevent = this.onSelectforevent.bind(this);
        this.getMemberListbyMobileNumber = this.getMemberListbyMobileNumber.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.getData();
    }

    handleFormChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    getMemberListbyMobileNumber = async (mobile) => {
        const response = await axios.post(process.env.REACT_APP_TALK_API + `/eventTeam/getUserDetails`, { mobile })
            .then(res => res.data)

        if (response.Status === 200) {
            this.setState({
                // changeToView: true,
                member: response.data,
                // yin_id:response.data,
                // mobile:response.data,

            })

        }
        else {
            alert('Record not found');
        }
    }
    handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await axios
                .post(process.env.REACT_APP_TALK_API + "1/registerevent/add/event/member",
                    {
                        event_id: this.state.event.event_id,
                        member: {
                            profile_image: this.state.member.profile_image,
                            first_name: this.state.member.first_name,
                            last_name: this.state.member.last_name,
                            college_name: this.state.member.college_name,
                            event_title: this.state.event.event_title,
                            event_amount: this.state.event.event_amount,
                            event_discount: this.state.event.event_discount,
                            is_paid: this.state.event.is_paid,
                            receiptNumber: this.state.receiptNumber,
                            receipt_Photo: this.state.receipt_Photo
                        },
                    }
                )
                .then((res) => {

                    if (res.status === 200) {
                        alert("New Attendee Added successfully");
                        this.setState({

                        })
                    }
                    else if (res.status !== 200) {
                        alert("Member already added")
                    }
                })
                .catch((error) => {
                    console.log("----error--------", error);
                });
        } catch (error) {
            console.log("------error------", error);
            if (this.state.receiptNumber === null || this.state.receiptNumber === '') {
                this.setState({
                    receipt_number_error: "Please enter receipt number"
                });
            }

            if (this.state.receipt_Photo === null || this.state.receipt_Photo === '') {
                this.setState({
                    receipt_number_error: '',
                    receipt_photo_error: "Please enter receipt photo"
                });
            } else {

                this.setState({
                    receipt_number_error: '',
                    receipt_photo_error: ""
                });

                try {
                    const transactionDetails = {
                        amount: Number(this.state.event.event_amount),
                        event_id: this.state.event.event_id, //Event ID
                        order_id: "ORDER_OFFLINE" + Math.floor(Math.random() * (100000 - 999999)),
                        mobile: this.state.member.mobile,
                        email: this.state.member.email ? this.state.member.email : 'younginspiratorsnetwork@gmail.com',
                        type: 'LIVE',
                        platform: 'OTHER',
                    };

                    let transactionData = await axios.post(process.env.REACT_APP_APIURLV2 + "/payments/transactions", transactionDetails);
                    await axios.post(process.env.REACT_APP_TALK_API + `/registerevent/add/event/member`,
                        {
                            event_id: this.state.event.event_id,
                            member: {
                                yin_id: this.state.member.yin_id,
                                user_type: this.state.member.user_type,
                                status: this.state.member.status,
                                profile_image: this.state.member.profile_image,
                                first_name: this.state.member.first_name,
                                last_name: this.state.member.last_name,
                                mobile: this.state.member.mobile,
                                email: this.state.member.email,
                                city: this.state.member.city,
                                college_code: this.state.member.college_code,
                                college_name: this.state.member.college_name,
                                event_title: this.state.event.event_title,
                                event_amount: this.state.event.event_amount,
                                event_discount: this.state.event.event_discount,
                                is_paid: this.state.event.is_paid,
                                receiptNumber: this.state.receiptNumber,
                                receipt_Photo: this.state.receipt_Photo,
                                payment_type: this.state.payment_type
                            },
                        }
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                axios.post(process.env.REACT_APP_APIURL + "/eventpdf/create-pdf", {
                                    yin_id: this.state.member.yin_id,
                                    mobile: this.state.member.mobile,
                                    event_id: this.state.event.event_id
                                }).then((data) => {
                                    alert("New Attendee Added successfully");
                                }).catch((error) => {
                                    console.log("-------------error in send sms and create pdf-------------", error);
                                });
                            }
                            else if (res.status !== 200) {
                                alert("Member already added");
                            }
                        })
                        .catch((error) => {
                            console.log("----error--------", error);
                        });
                } catch (error) {
                    console.log("------error------", error);
                }
            }


        };
    }



    pdfData(event) {
        event.preventDefault();

        const pdf = {
            "yin_id": this.state.member.yin_id,
            "mobile": this.state.member.mobile,
            "event_id": this.state.event_id,
        };

        axios.post(process.env.REACT_APP_APIURL + `/eventpdf/user/registeration/pdf/create`, pdf).then(res => {
            if (res.status === 200) {
                alert("Message send successfully!!!")
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                errorsUpload: "Please Go Back and Try again later..."
            })
        });

    }


    //upload image

    uploadImageToS3(event) {
        this.setState({
            receipt_photo_error: "",
            receipt_photo_errors: "Image uploading...",
            disabledd: true
        });

        const image = event.target.files[0];
        if (!image) return;

        if (image.type === 'image/png' || image.type === 'image/jpeg') {
            new Compressor(image, {
                quality: 0.6,
                success: async (compressedResult) => {
                    try {
                        const fileName = image.name.replace(/\..+$/, "");
                        const fileType = image.type;

                        // Use the uploadImageFromBackend utility to upload the image
                        const imageURL = await uploadImageFromBackend(fileName, fileType, compressedResult);

                        if (imageURL) {
                            this.setState({
                                url: imageURL,
                                receipt_Photo: imageURL,
                                receipt_photo_errors: "image uploaded",
                                disabledd: false
                            });
                        } else {
                            console.error("Image upload failed.");
                        }
                    } catch (error) {
                        console.error("Error uploading image:", error);
                    }
                },
                error: (err) => {
                    console.error("Compression error:", err.message);
                },
            });
        } else {
            console.error("Invalid file type. Please upload a PNG or JPEG image.");
        }
    }



    getData() {
        let currentComponent = this;
        axios.get(process.env.REACT_APP_TALK_API + `/event/all/list`).then(res => {
            let EventList = res.data.data;

            currentComponent.setState({
                eventlist: EventList,

                city: JSON.parse(localStorage.getItem("districts")),
            });

        });

    }
    onSelectforevent(e) {

        this.setState({
            event_id: e.target.value,
        })

    }


    render() {

        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>

                    <div className="container">
                        <div className="row mt-4">
                            <div
                                style={{
                                    heigh: "800vh",
                                    overflow: "hidden",
                                }}
                                className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto ">

                                <div className="card rounded shadow shadow-sm " >
                                    <div className="card-header">
                                        <h3 className="mb-0 center">Add New Member</h3>
                                    </div>
                                    <div className="card-body">

                                        <div className="form-group">
                                            <label htmlFor="event_title">Select Event</label>
                                            <select id="event_title" className="form-control rounded-1" name="event_title" onChange={this.onSelectforevent} >
                                                <option disabled selected>--Choose Event--</option>
                                                {this.state.eventlist.map((e, index) => {
                                                    return <option key={index} value={e.event_id} >{e.event_title}  </option>;
                                                })}
                                            </select>
                                        </div>
                                        <br />
                                        <hr className="mx-n3" />
                                        {
                                            this.state.event_id &&
                                            <div className="form-group">
                                                <label htmlFor="mobile" className="control-label">Mobile Number</label>
                                                <input type="text" id="mobile" name="mobile" value={this.state.mobile} onChange={this.handleFormChange} className="form-control" placeholder="Type here"  ></input>
                                                <div className="errorMsg">{this.state.errors.mobile}</div>
                                            </div>
                                        }
                                        {
                                            this.state.event_id &&
                                            <div className="form-group">
                                                <button onClick={() => this.getMemberListbyMobileNumber(this.state.mobile)} id="btnSubmitSignup" className="btn btn-primary btn-lg btn-block" style={{ marginLeft: "370px" }}>Submit</button>
                                            </div>
                                        }
                                        <br />

                                        <form id="AddAttendee" onSubmit={this.handleSubmit}>
                                            {this.state.member && (
                                                <div>
                                                    <div className="row mt-5">
                                                        <div className="col-lg-5 col-md-5 mx-auto">
                                                            <div className="card rounded shadow shadow-sm" style={{ minHeight: "200px" }}>
                                                                <div className="card-header1">
                                                                    <h3 className="card-title mb-0 text-center"><b>Profile</b></h3>
                                                                    <div className="row align-items-center" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                                                        <div className="image">
                                                                            {this.state.member.profile_image[0] ? <img src={this.state.member.profile_image[0]} alt="imhe" width="320px" height="140px" /> :
                                                                                <img src={logo} alt="image_logo" />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="card-header1  text-center">
                                                                        <div>
                                                                            <label htmlFor="name"><h5><b>{this.state.member.first_name}&nbsp;{this.state.member.last_name}</b></h5></label><br />
                                                                            <label htmlFor="college_name">{this.state.member.college_name}</label><br />
                                                                            <label htmlFor="college_name">{this.state.member.mobile}</label><br />
                                                                            <h5>YinId: <b>{this.state.member.yin_id}</b></h5>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="form-group">
                                                        <button onClick={this.pdfData} id="btnSubmitSignup" className="btn btn-primary btn-lg btn-block" style={{ marginLeft: "370px" }}>Add</button>

                                                    </div>
                                                </div>
                                            )
                                            }
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}