import React, { useState } from 'react';
import { Button, Card, Col, Container, Form, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import * as Icon from 'react-bootstrap-icons';
import * as XLSX from 'xlsx';
import { toast, ToastContainer } from 'react-toastify';
import moment from "moment";

const ManageElection = () => {

    const [collegeCode, setCollegeCode] = useState('');
    const [collegeCodeErr, setCollegeCodeErr] = useState(false);
    const [loader, setLoader] = useState(false);

    // States for Stop LDP & Voter Registration ---------
    const [collegeCodes1, setCollegeCodes1] = useState('');
    const [collegeCode1Err, setCollegeCode1Err] = useState(false);
    const [loader1, setLoader1] = useState(false);
    const [isVotingStart, setIsVotingStart]  = useState(false);

    // States for Start LDP -----------------
    const [collegeCodes2, setCollegeCodes2] = useState('');
    const [collegeCode2Err, setCollegeCode2Err] = useState(false);
    const [loader2, setLoader2] = useState(false);

    const [startDate2, setStartDate2] = useState('');
    // const [startDate2Err, setStartDate2Err] = useState(false);
    const [endDate2, setEndDate2] = useState('');
    // const [endDate2Err, setEndDate2Err] = useState(false);

    const [startTime2, setStartTime2] = useState('');
    // const [startTime2Err, setStartTime2Err] = useState(false);
    const [endTime2, setEndTime2] = useState('');
    // const [endTime2Err, setEndTime2Err] = useState(false);


      // States for Start & Stop Election -----------------
      const [collegeCodes3, setCollegeCodes3] = useState('');
      const [collegeCode3Err, setCollegeCode3Err] = useState(false);
      const [isElectionStart, setIsElectionStart]  = useState(false);
      const [loader3, setLoader3] = useState(false);
  
      const [startDate3, setStartDate3] = useState('');
      const [endDate3, setEndDate3] = useState('');
  
      const [startTime3, setStartTime3] = useState('');
      const [endTime3, setEndTime3] = useState('');

      // states for election result ----
      const [collegeCodes4, setCollegeCodes4] = useState('');
      const [collegeCode4Err, setCollegeCode4Err] = useState(false);
      const [loader4, setLoader4] = useState(false);
      const [isResult, setIsResult]  = useState(false);

    const convertToIST = (utcDate) => {
        // Parse the UTC date string into a Date object
        const date = new Date(utcDate);

        // Get the UTC timestamp and add the IST offset (5 hours 30 minutes in milliseconds)
        const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
        const istDate = new Date(date.getTime() + istOffset);

        // Format the IST date as a string (you can customize this format)
        return istDate.toISOString().replace("T", " ").slice(0, 19);
    };

    // const handleDownload = async () => {
    //     if (!collegeCode) {
    //         setCollegeCodeErr(true);
    //         return;
    //     }

    //     try {
    //         setLoader(true);
    //         const res = await fetch(process.env.REACT_APP_VOTINGAPI + `/voting/resultDetailsByCollegeCode/${collegeCode}`, {
    //             method: 'GET'
    //         });
    //         const response = await res.json();
    //         if (response?.list?.length > 0) {

    //             const heading = []
    //             let maxlen = Math.max(response.list.length);
    //             let allCountData = {
    //                 "All Votes": 3,
    //                 "Voted Users Count": 3,
    //                 "All Approved Voter Count": 2,
    //                 "All Rejected Voter Count": 1,
    //                 "Not Voted User Count": 75,
    //             }
    //             heading.push(allCountData);
    //             heading.push({});
    //             if (response)
    //                 for (let i = 0; i < maxlen; i++) {
    //                     let obj = {
    //                         "Voter Name": response?.list[i].first_name + " " + response?.list[i].last_name,
    //                         // "Voter YIN ID": response?.list[i].yin_id,
    //                         "Gender": response?.list[i].gender,
    //                         "City": response?.list[i].city,
    //                         "Election Year": response?.list[i].electionYear ?? "2025",
    //                         "Candidate Name": response?.list[i].candidate_first_name + " " + response?.list[i].candidate_last_name,
    //                         "Candidate Mobile": response?.list[i].candidate_mobile,
    //                         "Candidate YIN ID": response?.list[i].candidate_yinid,
    //                         "College Name": response?.list[i].college_name,
    //                         "Voter Card Status": response?.list[i].isVoterIdCardStatus ?? "REJECTED",
    //                         "Voting Time": convertToIST(response?.list[i].vote_time),
    //                         // "Indian Time": convertToIST(response?.list[i].vote_time),
    //                     }
    //                     heading.push(obj);
    //                 }
    //             downloadExcel(heading, response?.list[0].college_name);


    //             setLoader(false);

    //         }
    //         else {
    //             setCollegeCodeErr(true);
    //         }
    //     }
    //     catch (Err) {
    //         console.log("Error while downloading", Err);
    //     }
    //     finally {
    //         setLoader(false);
    //     }

    // }

    const handleDownload = async () => {
        if (!collegeCode) {
            setCollegeCodeErr(true);
            return;
        }

        try {
            setLoader(true);

            const res = await fetch(
                `${process.env.REACT_APP_VOTINGAPI}/voting/resultDetailsByCollegeCode/${collegeCode}`,
                { method: 'GET' }
            );
            const response = await res.json();
            const heading = []
            const heading2 = []
            const heading3 = []
            const heading4 = []

            // console.log("----",response)
            if (response?.list?.length > 0) {

                const allCountData = {
                    "All Votes": response?.All_Votes??0,
                    "All Approved Voter Count": response?.All_Approved_Voter_Count??0,
                    "All Rejected Voter Count": response?.All_Rejected_Voter_Count??0,
                    "Not Voted User Count": response?.Not_Voted_User_Count??0,
                };

                const allCountDataRows = Object.entries(allCountData).map(([key, value]) => ({
                    Metric: key,
                    Value: value,
                }));

                const heading5 = [...allCountDataRows, {}, {}];
                
                response.list.forEach((item) => {
                    const row = {
                        "Voter Name": `${item.first_name} ${item.last_name}`,
                        "Gender": item.gender,
                        "City": item.city,
                        "Election Year": item.electionYear ?? "2025",
                        "Candidate Name": `${item.candidate_first_name} ${item.candidate_last_name}`,
                        "Candidate Mobile": item.candidate_mobile,
                        "Candidate YIN ID": item.candidate_yinid,
                        "College Name": item.college_name,
                        "Voter Card Status": item.isVoterIdCardStatus ?? "REJECTED",
                        "Voting Time": convertToIST(item?.vote_time),
                    };
                    heading.push(row);
                });

                response.Approve_user_list.forEach((item) => {
                    const row = {
                        "Voter Name": `${item.first_name} ${item.last_name}`,
                        "Gender": item.gender,
                        "City": item.city,
                        "Election Year": item.electionYear ?? "2025",
                        "Candidate Name": `${item.candidate_first_name} ${item.candidate_last_name}`,
                        "Candidate Mobile": item.candidate_mobile,
                        "Candidate YIN ID": item.candidate_yinid,
                        "College Name": item.college_name,
                        "Voter Card Status": item.isVoterIdCardStatus ?? "REJECTED",
                        "Voting Time": convertToIST(item?.vote_time),
                    };
                    heading2.push(row);
                });

                response.votedUsersRejected.forEach((item) => {
                    const row = {
                        "Voter Name": `${item.first_name} ${item.last_name}`,
                        "Gender": item.gender,
                        "City": item.city,
                        "Election Year": item.electionYear ?? "2025",
                        "Candidate Name": `${item.candidate_first_name} ${item.candidate_last_name}`,
                        "Candidate Mobile": item.candidate_mobile,
                        "Candidate YIN ID": item.candidate_yinid,
                        "College Name": item.college_name,
                        "Voter Card Status": item.isVoterIdCardStatus ?? "REJECTED",
                        "Voting Time": convertToIST(item.vote_time),
                    };
                    heading3.push(row);
                });

                response.Not_voted_user.forEach((item) => {
                    const row = {
                        "Voter Name": `${item.first_name} ${item.last_name}`,
                        "Gender": item.gender,
                        "City": item.city,
                        "Election Year": item.electionYear ?? "2025",
                        "College Name": item.college_name,
                        "Voter Card Status": item.isVoterIdCardStatus ?? "REJECTED",
                    };
                    heading4.push(row);
                });

                downloadExcel(heading, heading2, heading3, heading4,heading5, `${response.list[0].college_name}`);
            } else {
                setCollegeCodeErr(true);
            }
        } catch (error) {
            console.error("Error while downloading", error);
        } finally {
            setLoader(false);
        }
    };


    const downloadExcel = (heading, heading2, heading3, heading4,heading5, fileName) => {
        let dataSet = [];
        dataSet = heading;
        const worksheet = XLSX.utils.json_to_sheet(heading5);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "All Count");

        const worksheet1 = XLSX.utils.json_to_sheet(dataSet);
        XLSX.utils.book_append_sheet(workbook, worksheet1, "All Vote List");
        //second Sheet
        const worksheet2 = XLSX.utils.json_to_sheet(heading2);
        XLSX.utils.book_append_sheet(workbook, worksheet2, "Approved Vote List");

        //Third Sheet
        const worksheet3 = XLSX.utils.json_to_sheet(heading3);
        XLSX.utils.book_append_sheet(workbook, worksheet3, "Rejected Vote List");
        //fourth sheet
        const worksheet4 = XLSX.utils.json_to_sheet(heading4);
        XLSX.utils.book_append_sheet(workbook, worksheet4, "Not Voted User List");

        XLSX.writeFile(workbook, `${fileName}.xlsx`);

    };

    // User Enter all college code in string, but api need array of string, so transforming here
    const transformToArray = (input) => {
        return input
          .split(/[\s,]+/)
          .map((code) => code.trim())
          .filter((code) => code !== "");
      };

    const handleStopLDPAndVoterRegistration = async ()=>{
        if(!collegeCodes1){
            setCollegeCode1Err(true);
            return;
        }
        try{
            const collegeCodes1Array = transformToArray(collegeCodes1);
            setLoader1(true);
            const res = await fetch(process.env.REACT_APP_ALL_API + '/coordinator/stop/ldp/election/voterregistration',{
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({
                    isVoterIdRegistrationStarted:isVotingStart,
                    college_code :collegeCodes1Array
                })
            })

            const response = await res.json();
            if(response === "successfully updated"){
                toast.success("Successfully Updated");
            }
            else{
                toast.error("Error, Please try later");
            }
        }catch(Err){
            console.log("Err while stop LDP & Voter registration", Err);
            toast.error("Error, Please try later");
        }finally{
            setLoader1(false);
            setCollegeCode1Err(false);
        }
    }


    const handleStopLDP =async()=>{

        if(!collegeCodes2 || !startDate2 || !startTime2 ||  !endDate2 ||  !endTime2){
            setCollegeCode2Err(true);
            return;
        }
        // if(!startDate2){
        //     setStartDate2Err(true);
        //     return;
        // }
        // if(!endDate2){
        //     setEndDate2Err(true);
        //     return;
        // }
        try{
            const collegeCodes2Array = transformToArray(collegeCodes2);
            setLoader2(true);
            const res = await fetch(process.env.REACT_APP_ALL_API + '/coordinator/ldp/start/selected',{
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({
                    college_code :collegeCodes2Array,
                    ldp_start_date:moment(startDate2).format("YYYY-MM-DD"),
                    ldp_end_date:moment(endDate2).format("YYYY-MM-DD"),
                    ldp_start_time:startTime2,
                    ldp_end_time:endTime2,
                    ldp_started:true,
                    ldp_ended:false
                })
            })

            const response = await res.json();
            console.log("response", response);
            if(response === "successfully updated"){
                toast.success("Successfully Updated");
            }
            else{
                toast.error("Error, Please try later");
            }
        }
        catch(Err){
            console.log("Err while Start LDP", Err);
            toast.error("Error, Please try later");
        }finally{
            setLoader2(false);
            setCollegeCode2Err(false);
        }
    }


    // handle Start & Stop Election ---------------------
    
    const handleStartAndStopElection =async()=>{

        if(!collegeCodes3 || !startDate3 || !startTime3 ||  !endDate3 ||  !endTime3){
            setCollegeCode3Err(true);
            return;
        }
        try{
            const collegeCodes3Array = transformToArray(collegeCodes3);
            setLoader3(true);
            const res = await fetch(process.env.REACT_APP_ALL_API + '/coordinator/election/start/selected/colleges',{
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({
                    college_code :collegeCodes3Array,
                    ldp_start_date:moment(startDate3).format("YYYY-MM-DD"),
                    ldp_end_date:moment(endDate3).format("YYYY-MM-DD"),
                    ldp_start_time:startTime3,
                    ldp_end_time:endTime3,
                    election_status:isElectionStart
                })
            })

            const response = await res.json();
            if(response === "successfully updated"){
                toast.success("Successfully Updated");
            }
            else{
                toast.error("Error, Please try later");
            }
        }
        catch(Err){
            console.log("Err while Start LDP", Err);
            toast.error("Error, Please try later");
        }finally{
            setLoader3(false);
            setCollegeCode3Err(false);
        }
    }

    const handleShowAndHideResult = async ()=>{
        if(!collegeCodes4){
            setCollegeCode4Err(true);
            return;
        }
        try{
            const collegeCodes4Array = transformToArray(collegeCodes4);
            setLoader4(true);
            const res = await fetch(process.env.REACT_APP_ALL_API + '/coordinator/election/result/status/selected',{
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({
                    college_code :collegeCodes4Array,
                    results_available_status:isResult
                })
            })

            const response = await res.json();
            if(response === "successfully updated"){
                toast.success("Successfully Updated");
            }
            else{
                toast.error("Error, Please try later");
            }
        }catch(Err){
            console.log("Err while stop LDP & Voter registration", Err);
            toast.error("Error, Please try later");
        }finally{
            setLoader4(false);
            setCollegeCode4Err(false);
        }
    }



    return (
        <>
        <ToastContainer/>
            <Sidebar />
            <div className="main">
                <div className="topbar">
                    <Navbar />
                </div>
                <div className="container">
                    {/* <BackAndBreadCrmOneParent
          backLink=""
          backName=""
          currentTabName="Election List"
        /> */}
                    <h2 className="text-center  mt-3">Manage Election</h2>
                </div>

                <br></br>

                <Container>
                    <Row className='d-flex justify-content-center pe-0 pe-md-4'>
                        <Col md={3}>
                            <Form.Control placeholder='Enter College Code here' value={collegeCode} onChange={(e) => {
                                setCollegeCode(e.target.value);
                                e.target.value.length > 0 ? setCollegeCodeErr(false) : setCollegeCodeErr(true)
                            }} />
                            {collegeCodeErr && <p style={{ fontSize: '14px', color: 'red', margin: '4px 0px 0px 4px' }}>Please enter valid college code</p>}
                        </Col>
                        <Col md={3}>
                            {
                                loader ?
                                    <div className='d-flex align-items-center h-100'>
                                        <p className='mb-0 text-success'>Downlaoding ...</p></div>
                                    :
                                    <Button className='bg-success border-0 w-100' onClick={handleDownload}>
                                        <Icon.FileEarmarkSpreadsheet className='me-1' /> Download Excel</Button>
                            }
                        </Col>
                    </Row>

                    <Row className='my-5 mx-3'>
                        <Card className='p-0'>
                            <Card.Header className='text-center'>
                                <h5 className='mb-0'>Stop LDP & Voter Registration for selected college codes</h5>
                            </Card.Header>
                            <Card.Body className='p-4'>
                                <Row>
                                <Col md={6}>
                                <Form.Label>College Codes (Enter , after each code)</Form.Label>
                                    <Form.Control as='textarea' rows={4} placeholder='Enter College Codes'
                                    value={collegeCodes1} onChange={(e)=>setCollegeCodes1(e.target.value)}/>
                                    {collegeCode1Err && <p style={{ fontSize: '14px', color: 'red', margin: '4px 0px 0px 4px' }}>Please enter college codes</p>}
                                </Col>

                                <Col md={6}>
                                    <Tab.Container id="voterRegistrationTabs" activeKey={isVotingStart ? "start" : "end"}
                                    onSelect={(key) => setIsVotingStart(key === 'start' ? true : false)}>
                                    <Nav variant="pills" className="flex-column flex-md-row justify-content-end mx-auto mt-4" >
                                        <Nav.Item >
                                        <Nav.Link eventKey="start" className={isVotingStart ?  'bg-success' : 'text-success'}>
                                            <div className="card-container " >
                                            <div className="d-flex py-1 px-2 justify-content-center rounded">
                                                <Icon.CheckCircleFill className="me-2" size={20} />
                                                <h6 className="mb-0">Voter Registration Start</h6>
                                            </div>
                                            </div>
                                        </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                        <Nav.Link eventKey="end" className={isVotingStart ?  'text-danger' : 'bg-danger'}>
                                            <div className="card-container">
                                            <div className="d-flex py-1 px-2 justify-content-center rounded">
                                                <Icon.StopCircleFill className="me-2" size={20} />
                                                <h6 className="mb-0">Voter Registration End</h6>
                                            </div>
                                            </div>
                                        </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    </Tab.Container>

                                    {
                                        loader1 ? <centre><Spinner/></centre> :
                                        <Button className='mx-auto d-block mt-4'
                                            onClick={handleStopLDPAndVoterRegistration}>
                                            Submit
                                        </Button>
                                    }
                                </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>


                    <Row className='my-5 mx-3'>
                        <Card className='p-0'>
                            <Card.Header className='text-center'>
                                <h5 className='mb-0'>Start LDP for selected college codes</h5>
                            </Card.Header>
                            <Card.Body className='p-4'>
                                <Row>
                                <Col md={6}>
                                <Form.Label>College Codes (Enter , after each code)</Form.Label>
                                    <Form.Control as='textarea' rows={4} placeholder='Enter College Codes'
                                    value={collegeCodes2} onChange={(e)=>setCollegeCodes2(e.target.value)}/>
                                  
                                </Col>

                                <Col md={6} className='mt-4 mt-md-0'>
                                    <div className='d-flex justify-content-center gap-3'>
                                
                                    <Form.Group>
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control type='date' placeholder='Start Date' value={startDate2} 
                                    onChange={(e)=>setStartDate2(e.target.value)}/>
                                    {/* {startDate2Err && <p style={{ fontSize: '14px', color: 'red', margin: '4px 0px 0px 4px' }}>Please enter start date</p>} */}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Start Time</Form.Label>
                                        <Form.Control type='time' placeholder='Start Time' value={startTime2} 
                                        onChange={(e)=>setStartTime2(e.target.value)}/>
                                        {/* {startTime2Err && <p style={{ fontSize: '14px', color: 'red', margin: '4px 0px 0px 4px' }}>Please enter start time</p>} */}
                                        </Form.Group>

                                    

                                    </div>

                                    <div className='d-flex justify-content-center gap-3 mt-4'>
                                        
                                    <Form.Group>
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control type='date' disabled={!startDate2} min={startDate2} placeholder='End Date' value={endDate2} 
                                    onChange={(e)=>setEndDate2(e.target.value)}/>
                                    {/* {endDate2Err && <p style={{ fontSize: '14px', color: 'red', margin: '4px 0px 0px 4px' }}>Please enter end date</p>} */}
                                    </Form.Group>

                                        <Form.Group>
                                        <Form.Label>End Time</Form.Label>
                                        <Form.Control type='time' placeholder='End Time' value={endTime2} 
                                        onChange={(e)=>setEndTime2(e.target.value)}/>
                                        {/* {endTime2Err && <p style={{ fontSize: '14px', color: 'red', margin: '4px 0px 0px 4px' }}>Please enter end time</p>} */}
                                        </Form.Group>

                                     </div>

                                    
                                </Col>
                                {collegeCode2Err && <p style={{ fontSize: '14px', color: 'red', margin: '12px 0px 0px 0px', textAlign:'center' }}>Please enter all fields (College code, Start-End Date/Time)</p>}
                                {
                                        loader2 ? <centre><Spinner/></centre> :
                                        <Button className='mx-auto w-auto mt-4 mt-md-2'
                                            onClick={handleStopLDP}>
                                            Submit
                                        </Button>
                                    }
                                </Row>
                            </Card.Body> 
                        </Card>
                    </Row>


{/*-------------------- Start & Stop Election -----------------*/}
                    <Row className='my-5 mx-3'>
                        <Card className='p-0'>
                            <Card.Header className='text-center'>
                                <h5 className='mb-0'>Start & Stop Election for selected college codes</h5>
                            </Card.Header>
                            <Card.Body className='p-4'>
                                <Row>
                                <Col md={6}>
                                <Form.Group>
                                     <Form.Label>College Codes (Enter , after each code)</Form.Label>
                                    <Form.Control as='textarea' rows={4} placeholder='Enter College Codes'
                                    value={collegeCodes3} onChange={(e)=>setCollegeCodes3(e.target.value)}/>
                                  </Form.Group>

                                  <Tab.Container id="electionTabs" activeKey={isElectionStart ? "start" : "end"}
                                    onSelect={(key) => setIsElectionStart(key === 'start' ? true : false)}>
                                    <Nav variant="pills" className="flex-column flex-md-row justify-content-end mx-auto mt-4" >
                                        <Nav.Item >
                                        <Nav.Link eventKey="start" className={isElectionStart ?  'bg-success' : 'text-success'}>
                                            <div className="card-container " >
                                            <div className="d-flex py-1 px-2 justify-content-center rounded">
                                                <Icon.CheckCircleFill className="me-2" size={20} />
                                                <h6 className="mb-0">Start Election</h6>
                                            </div>
                                            </div>
                                        </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                        <Nav.Link eventKey="end" className={isElectionStart ?  'text-danger' : 'bg-danger'}>
                                            <div className="card-container">
                                            <div className="d-flex py-1 px-2 justify-content-center rounded">
                                                <Icon.StopCircleFill className="me-2" size={20} />
                                                <h6 className="mb-0">Stop Election</h6>
                                            </div>
                                            </div>
                                        </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    </Tab.Container>
                                </Col>

                                <Col md={6} className='mt-4 mt-md-0'>
                                    <div className='d-flex justify-content-center gap-3'>
                                
                                    <Form.Group>
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control type='date' placeholder='Start Date' value={startDate3} 
                                    onChange={(e)=>setStartDate3(e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Start Time</Form.Label>
                                        <Form.Control type='time' placeholder='Start Time' value={startTime3} 
                                        onChange={(e)=>setStartTime3(e.target.value)}/>
                                        </Form.Group>
                                    </div>

                                    <div className='d-flex justify-content-center gap-3 mt-4'>
                                        
                                    <Form.Group>
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control type='date' disabled={!startDate3} min={startDate3} placeholder='End Date' value={endDate3} 
                                    onChange={(e)=>setEndDate3(e.target.value)}/>
                                    </Form.Group>

                                        <Form.Group>
                                        <Form.Label>End Time</Form.Label>
                                        <Form.Control type='time' placeholder='End Time' value={endTime3} 
                                        onChange={(e)=>setEndTime3(e.target.value)}/>
                                        </Form.Group>

                                     </div>

                                    
                                </Col>
                                {collegeCode3Err && <p style={{ fontSize: '14px', color: 'red', margin: '12px 0px 0px 0px', textAlign:'center' }}>Please enter all fields (College code, Start-End Date/Time)</p>}
                                {
                                        loader3 ? <centre><Spinner/></centre> :
                                        <Button className='mx-auto w-auto mt-4 mt-md-2'
                                            onClick={handleStartAndStopElection}>
                                            Submit
                                        </Button>
                                    }
                                </Row>
                            </Card.Body> 
                        </Card>
                    </Row>

                    

{/*-------------------- Start & Stop Election -----------------*/}
<Row className='my-5 mx-3'>
                        <Card className='p-0'>
                            <Card.Header className='text-center'>
                                <h5 className='mb-0'>Show & Hide Election Result for selected college codes</h5>
                            </Card.Header>
                            <Card.Body className='p-4'>
                                <Row>
                                <Col md={6}>
                                <Form.Group>
                                     <Form.Label>College Codes (Enter , after each code)</Form.Label>
                                    <Form.Control as='textarea' rows={4} placeholder='Enter College Codes'
                                    value={collegeCodes4} onChange={(e)=>setCollegeCodes4(e.target.value)}/>
                                  </Form.Group>
                                {collegeCode4Err && <p style={{ fontSize: '14px', color: 'red', margin: '12px 0px 0px 0px', textAlign:'center' }}>Please enter at least one college code</p>}
                                </Col>

                                <Col md={6}>
                                <Tab.Container id="electionTabs" activeKey={isResult ? "start" : "end"}
                                    onSelect={(key) => setIsResult(key === 'start' ? true : false)}>
                                    <Nav variant="pills" className="flex-column flex-md-row justify-content-end mx-auto mt-4" >
                                        <Nav.Item >
                                        <Nav.Link eventKey="start" className={isResult ?  'bg-success' : 'text-success'}>
                                            <div className="card-container " >
                                            <div className="d-flex py-1 px-2 justify-content-center rounded">
                                                <Icon.CheckCircleFill className="me-2" size={20} />
                                                <h6 className="mb-0">Show Result</h6>
                                            </div>
                                            </div>
                                        </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                        <Nav.Link eventKey="end" className={isResult ?  'text-danger' : 'bg-danger'}>
                                            <div className="card-container">
                                            <div className="d-flex py-1 px-2 justify-content-center rounded">
                                                <Icon.StopCircleFill className="me-2" size={20} />
                                                <h6 className="mb-0">Hide Result</h6>
                                            </div>
                                            </div>
                                        </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    </Tab.Container>

                                {
                                        loader4 ? <centre><Spinner/></centre> :
                                        <Button className='mx-auto d-block mt-4 '
                                            onClick={handleShowAndHideResult}>
                                            Submit
                                        </Button>
                                    }
                                </Col>

                               
                                </Row>
                            </Card.Body> 
                        </Card>
                    </Row>




                    
                </Container>
            </div>
        </>
    )
}

export default ManageElection;