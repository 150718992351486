import React, { Component } from "react";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.css";
import logo from "../Assets/images/yin-full.png";
import axios from "axios";
import LoaderRounded from "./LoaderRounded";
// import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as actions from "./../actions/authAction";
import { setLoginData } from "../Redux/Slice/UserDataSlice";
// import { setAwsCreds } from "../Redux/Slice/awsCredSlice";

class AdminLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      islogged: false,
      mobile: "",
      password: "",
      first_name: "",
      last_name: "",
      profile_image: "",
      email: "",
      college_name: "",
      city: "",
      role: "",
      districts: "",
      errros: "",
      errors: [],
      loader: false,
      redirect: false,
      isloggedAccount: false,
      showPass: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
    this.handlePass = this.handlePass.bind(this);
  }


  //code for handle forms input
  handleFormChange(event) {
    // let errors = {}
    // if(event.target.name === "mobile"){
    //   if(event.target.value.match(/[A-Za-z+@#$&%!~]/)){
    //     errors["mobile"] = "*Please enter valid mobile no.";
    //   }else{
    //     this.setState({
    //       [event.target.name]: event.target.value
    //     });
    //   }
    // }else{
    this.setState({
      [event.target.name]: event.target.value,
    });
    // }
    // this.setState({
    //   errors: errors
    // })
  }

  handleFormValidation = () => {
    const { mobile, password } = this.state;
    let errors = {};
    let formIsValid = true;
    if (!mobile) {
      formIsValid = false;
      errors["mobile"] = "*Please Enter Contact Number.";
    } else if (!mobile.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
      formIsValid = false;
      errors["mobile"] = "*Please enter valid mobile no.";
    }
    if (!password) {
      formIsValid = false;
      errors["password"] = "*Please Enter password.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };


  // setAwsCreds = async () => {
  //   await axios.get(process.env.REACT_APP_APIURL + `/credentials/get/aws/?name=AWS`)
  //     .then((response) => {
  //       this.props.awsCreds(response.data.data);
  //     }).catch((Err) => {
  //       console.log("Err while getting aws creds", Err);
  //     })
  // }

  //code to submit data
  async handleSubmit(event) {
    if (this.handleFormValidation()) {
      event.preventDefault();

      const Login = {
        mobile: this.state.mobile,
        password: this.state.password,
      };

      if (this.handleFormValidation()) {
        event.preventDefault();

        const Login = {
          mobile: this.state.mobile,
          password: this.state.password,
        };

        let statesSet = this;
        this.setState({
          loader: true,
        });
        await axios
          .post(
            process.env.REACT_APP_APIURL + `/coordinator/cordinator-login`,
            Login
          )
          .then((res) => {
            this.setState({
              loader: false,
            });
            if (res.data) {
              if (
                res.data.user.role === "COORDINATOR" ||
                res.data.user.role === "ADMIN" ||
                res.data.user.role === "SUPERADMIN" ||
                res.data.user.role === "YINADMIN"
              ) {
                // alert('login successfully.')

                if (res.data.errors === "No user found") {
                  alert("No user found");

                  statesSet.setState({
                    errros: "Please enter correct credentials!!!",
                    redirect: true,
                  });
                } else {
                  // this.props.mobile(userMobile)
                  // this.setAwsCreds();
                  localStorage.setItem("mobile", this.state.mobile);
                  localStorage.setItem("first_name", res.data.user.first_name);
                  localStorage.setItem("last_name", res.data.user.last_name);
                  localStorage.setItem("email", res.data.user.email);
                  localStorage.setItem(
                    "profile_image",
                    res.data.user.profile_image
                  );
                  localStorage.setItem("city_name", res.data.user.city);
                  if (res.data.user.sub_city) {
                    localStorage.setItem(
                      "sub_city_name",
                      res.data.user.sub_city
                    );
                  }
                  localStorage.setItem(
                    "college_name",
                    res.data.user.college_name
                  );
                  localStorage.setItem("role", res?.data?.user?.role);
                  localStorage.setItem(
                    "districts",
                    JSON.stringify(res.data.user.districts)
                  );
                  localStorage.setItem("isLoggedIn", true);

                  if (res.data.user.is_accountent) {
                    statesSet.setState({
                      isloggedAccount: true,
                    });
                  } else {
                    statesSet.setState({
                      islogged: true,
                    });
                  }
                }
                this.props.Userlogin(res.data.user);
              } else {
                statesSet.setState({
                  errros: "Please enter correct credentials!!!",
                });
              }
              this.props.history.push("/dashboard");
            }
          })
          .catch((error) => {
            this.setState({
              loader: false,
            });
            alert("Invalid credetials");
            console.log("error in login", error);
            statesSet.setState({
              errros:
                "Please enter correct credentials!!! or Try again later...",
            });
          });
      }
    }
  }

  //Handle Pass
  handlePass = () => {
    this.setState({
      showPass: !this.state.showPass,
    });
  };

  UNSAFE_componentWillMount() {
    if (
      this.props.userDetails.UserData &&
      this.props.userDetails.UserData.role === "YINADMIN"
    ) {
      window.location.href = "/dashboard";
    }
  }

  render() {
    // if (this.state.isloggedAccount) {
    //   return <Redirect to='/view/qrcode/list' />;
    // }

    return (
      <div className="container w-100 h-100">
        <div className="row m-1 parent">
          <div className="col-xxl-4 col-lg-7 col-md-8 col-xs-12 col-12 m-auto p-0">
            <form
              id="loginForm"
              className="selectBox p-4"
              onSubmit={this.handleSubmit}
              method="post"
              encType="multipart/form-data"
            >
              <div className="col-12 m-auto p-0 text-center">
                <img src={logo} alt="YIN Logo" className="img-responsive" />
              </div>
              <hr />
              <h2 className="text-center">LOGIN</h2>
              <br />
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <span className="fa fa-user icon  mt-2 mb-2"></span>
                    </span>
                  </div>
                  <input
                    type="text"
                    minlength="10"
                    maxlength="10"
                    name="mobile"
                    value={this.state.mobile}
                    className="form-control"
                    onChange={(event) => {
                      if (
                        event.target.value.match(/[A-Za-z+@#$&%!~^*()_]/) === null
                      ) {
                        this.setState({
                          [event.target.name]: event.target.value,
                        });
                      }
                    }}
                    placeholder="Enter Mobile Number"
                    required={true}
                  />
                </div>
                <div className="errorMsg">{this.state.errors.mobile}</div>
              </div>
              <br />
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-lock icon mt-2 mb-2"></i>
                    </span>
                  </div>
                  <input
                    type={this.state.showPass ? "text" : "password"}
                    name="password"
                    className="form-control"
                    onChange={this.handleFormChange}
                    placeholder="Enter Password"
                    required={true}
                  />
                  {this.state.showPass ? (
                    <div
                      className="input-group-prepend"
                      style={{ cursor: "pointer" }}
                      onClick={this.handlePass}
                    >
                      <span className="input-group-text">
                        <span className="fa fa-eye-slash icon mt-2 mb-2"></span>
                      </span>
                    </div>
                  ) : (
                    <div
                      className="input-group-prepend"
                      style={{ cursor: "pointer" }}
                      onClick={this.handlePass}
                    >
                      <span className="input-group-text">
                        <span className="fa fa-eye icon mt-2 mb-2"></span>
                      </span>
                    </div>
                  )}
                  <div>{this.state.errors.password}</div>
                </div>
              </div>
              <div className="form-group text-center">
                <div className="errorMsg divider-20">{this.state.errros}</div>
                <button
                  type="submit"
                  name="submit"
                  className="btn btn-primary btn-padding-new divider-10"
                >
                  {this.state.loader ? <LoaderRounded /> : "Login"}
                </button>
                {/* <div className="errorMsg divider-20">Your log in has been disabled for until further notice.</div> */}
              </div>
            </form>
          </div>
        </div>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.PersistReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Userlogin: (data) => dispatch(setLoginData(data)),
    // awsCreds: (data) => dispatch(setAwsCreds(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
