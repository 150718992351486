import React, { Component } from "react";
import axios from "axios";
// import Resizer from "react-image-file-resizer";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
//import { Link } from "react-router-dom";
//import '../Assests/Styles.css';
import { Redirect } from "react-router-dom";
import Select from "react-select";
// import { ThreeSixtyOutlined } from "@material-ui/icons";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import ImagePopup from "./ImagePopup";
import ImageIdPopup from "./ImageIdPopup";
import ImageGovtIdPopup from "./ImageGovtIdPopup";
// import Compressor from 'compressorjs';
import S3 from "react-aws-s3";
import LoaderRounded from "./LoaderRounded";
import moment from "moment";
import dayjs from "dayjs";
import { uploadImageFromBackend } from "../Utils/uploadImage";

class Editcandidate extends Component {
  constructor(pops) {
    super(pops);

    this.state = {
      errors: [],
      address_line_1: "",
      images: "",
      approved_image: "",
      address_line_2: "",
      application_status: "",
      city: "",
      college_code: [],
      collegeCodes: [],
      college_year: "",
      approved_candidate_form: "",
      college_id: [],
      government_id: [],
      candidate_form: [],
      college_name: "",
      country: "",
      created_at: "",
      description: "",
      dob: "",
      document: "",
      email: "",
      email_is_verified: false,
      first_name: "",
      gender: "",
      last_name: "",
      middle_name: null,
      mobile: "",
      pincode: "",
      profile_completion: 0,
      profile_image: "",
      responsibility: [],
      roles: [],
      state: "",
      status: false,
      loader: false,
      taluka: "",
      user_type: "",
      verified: false,
      yin_id: "",
      dropDownOptCollegeList: [],
      redirect: false,
      StateData: [],
      CityData: [],
      showingImage: "",
      errorsGETDATA: "",
      errorsUpload: "",
      showModalPopup: false,
      showimageIdPopup: false,
      showGovtIdPopup: false,
      modelImageLoader: false,
      showUploadedForm: false,
      isDisabled: false,
      pdfdownload: false,
      college_code: localStorage.getItem("editcaollegecode"),
      cities: this.props.location.state?.city || null,
      year: this.props.location.state?.year || null,
    };
    this.initialState = this.state;
    this.handleReject = this.handleReject.bind(this);
    this.handleSubmitforapprovedcandodate =
      this.handleSubmitforapprovedcandodate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.getCollege = this.getCollege.bind(this);
    this.ChangeCity = this.ChangeCity.bind(this);
    this.showCity = this.showCity.bind(this);
    this.updateUserData = this.updateUserData.bind(this);
    this.isShowImageIdPopup = this.isShowImageIdPopup.bind(this);
    this.isShowGovtIdPopup = this.isShowGovtIdPopup.bind(this);
    this.isShowPopup = this.isShowPopup.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
    this.handlecreatepdf = this.handlecreatepdf.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  isShowPopup = (status, image) => {
    this.setState({
      showModalPopup: status,
      disabled: true,
      showingImage: image,
    });
  };

  isShowImageIdPopup = (status) => {
    this.setState({ showimageIdPopup: status });
  };

  isShowGovtIdPopup = (status) => {
    this.setState({ showGovtIdPopup: status });
  };

  isClosePopup = () => {
    this.setState({ showModalPopup: false });
    this.setState({ showimageIdPopup: false });
    this.setState({ showGovtIdPopup: false });
  };

  UNSAFE_componentWillMount() {
    this.getData();
    this.getCollege();
  }
  getData() {
    // let currentComponent = this;
    let yinID = localStorage.getItem("yin_id");
    axios.get(process.env.REACT_APP_APIURL + `/candidate/yin/` + yinID)
      .then((res) => {
        let responses = res.data;
        this.setState({
          address_line_1: responses[0]?.address_line_1,
          address_line_2: responses[0]?.address_line_2,
          application_status: responses[0]?.application_status,
          city: responses[0]?.city,
          college_code: responses[0]?.college_code,
          college_id: responses[0]?.college_id,
          government_id: responses[0]?.government_id,
          candidate_form: responses[0]?.candidate_form,
          college_name: responses[0]?.college_name,
          college_year: responses[0]?.college_year,
          country: responses[0]?.country,
          description: responses[0]?.description,
          dob: responses[0]?.dob,
          document: responses[0]?.document,
          email: responses[0]?.email,
          email_is_verified: responses[0]?.email_is_verified,
          first_name: responses[0]?.first_name.trim(),
          gender: responses[0]?.gender,
          last_name: responses[0]?.last_name.trim(),
          middle_name: responses[0]?.middle_name,
          mobile: JSON.stringify(responses[0]?.mobile),
          pincode: responses[0]?.pincode,
          profile_completion: responses[0]?.profile_completion,
          profile_image: responses[0]?.profile_image,
          responsibility: responses[0]?.responsibility,
          roles: responses[0]?.role,
          state: responses[0]?.state,
          status: responses[0]?.status,
          taluka: responses[0]?.taluka,
          user_type: responses[0]?.user_type,
          verified: responses[0]?.verified,
          yin_id: responses[0]?.yin_id,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorsGETDATA: "Candidate not found, Please Go Back and Try again...",
        });
      });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  getCollege = async () => {
    // let city = localStorage.getItem("city_name");
    let districts = JSON.parse(localStorage.getItem("districts"));
    if (districts.length) {
      const API = await axios.post(
        process.env.REACT_APP_APIURL + `/coordinator/college/all/city`,
        { districts }
      );
      const serverResponse = API.data;
      const dropDownValue = serverResponse.map((response) => ({
        value: response.college_code,
        label: response.college_name,
      }));
      this.setState({
        dropDownOptCollegeList: dropDownValue,
      });
    } else {
      console.log("No districts assigned to user");
    }
  };

  ChangeCity = (e) => {
    e.preventDefault();
    axios
      .get(process.env.REACT_APP_APIURL + "/address/city/" + e.target.value)
      .then((response) => {
        this.setState({
          CityData: response.data,
          state: e.target.value,
        });
      })
      .catch((error) => {
        console.log("------error in change city-------", error);
      });
  };

  showCity = (e) => {
    e.preventDefault();
    let index = e.target.selectedIndex;
    this.setState({ city: e.target[index].innerText });
  };

  //on change event for college list dropdown
  onChangeForCollegeListDropDown(event) {
    this.setState({
      college_code: event.value,
      college_name: event.label,
      isSelect: true,
    });
    localStorage.setItem("collegeCode", event.value);
    localStorage.setItem("collegeName", event.label);
  }

  handleFormValidation = () => {
    const {
      first_name,
      last_name,
      candidate_name,
      mobile,
      college_name,
      address_line_1,
      address_line_2,
      state_name,
      city_name,
      pincode,
    } = this.state;
    let errors = {};
    let formIsValid = true;
    //empty candidate name
    const regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
    if (!first_name) {
      formIsValid = false;
      errors["first_name"] = "*Please enter candidate name.";
    } else if (first_name.match(regName)) {
      formIsValid = false;
      errors["first_name"] = "*Please enter alphabet characters only.";
    }
    if (!last_name) {
      formIsValid = false;
      errors["last_name"] = "*Please enter candidate name.";
    } else if (regName.test(last_name)) {
      formIsValid = false;
      errors["last_name"] = "*Please enter alphabet characters only.";
    }

    //empty mobile no
    if (!mobile) {
      formIsValid = false;
      errors["mobile"] = "*Please enter mobile no.";
    }
    // else if (!mobile.match(/^[0-9]{10}$/)) {
    //   formIsValid = false;
    //   errors["mobile"] = "*Please enter valid mobile no.";
    // }

    if (!college_name) {
      formIsValid = false;
      errors["college_name"] = "*Please enter college name.";
    }
    //empty address_line_1
    if (!address_line_1) {
      formIsValid = false;
      errors["address_line_1"] = "*Please enter address.";
    }
    //empty address_line_2
    // if (!address_line_2) {
    //   formIsValid = false;
    //   errors["address_line_2"] = "*Please enter address.";
    // }

    //empty state
    if (state_name === "" || state_name === "--Choose State--") {
      formIsValid = false;
      errors["state_name"] = "Please Choose State.";
    }
    //empty city
    if (city_name === "" || state_name === "--Choose City--") {
      formIsValid = false;
      errors["city_name"] = "Please Choose State.";
    }

    var PincodePattern = new RegExp(/(^\d{6}$)/);
    //empty pincode
    if (!pincode) {
      formIsValid = false;
      errors["pincode"] = "*Please enter pincode.";
    }
    // else if (!PincodePattern.test(pincode)) {
    //   formIsValid = false;
    //   errors["pincode"] = "*Please enter valid pincode";
    // }

    // const validatEmaieEmailInput = (strEmail) => {
    //   let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    //   if (reg.test(strEmail.trim()) === false) return false;
    //   return true;
    // };
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  handleReject(event) {
    event.preventDefault();
    let formData = {
      yin_id: this.state.yin_id,
    };

    axios.post(process.env.REACT_APP_APIURL + `/coordinator/candidate/reject`, formData)
      .then((res) => {
        if (res.status === 200) {
          alert("Candidate reject successfully.");
          this.setState({ redirect: true, loader: true });
        }
      }).catch((error) => {
        console.log("error", error);
      })
  }

  handlecreatepdf(event) {
    event.preventDefault();
    this.setState({
      pdfdownload: true,
    });
    let formData = {
      yin_id: this.state.yin_id,
    };
    axios.post(process.env.REACT_APP_APIURL + "/candidate/create-pdf", formData)
      .then((res) => {
        if (res.status === 200) {
          this.onButtonClick(res.data);

          // this.setState({ redirect: true,
          //   loader:true,
          // });
        }
      })
      .catch((error) => {
        console.log("eror", error);
      })
  }

  onButtonClick = (link) => {
    // using Java Script method to get PDF file
    fetch(link).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "NominationForm.pdf";
        alink.click();
        this.setState({
          pdfdownload: false,
        });
      });
    });
  };

  handleSubmitforapprovedcandodate(event) {
    if (this.handleFormValidation()) {
      event.preventDefault();
      let formData = new FormData();

      formData.append("image", this.state.images);
      formData.append("yin_id", this.state.yin_id);
      formData.append("collegeName", this.state.college_name);
      formData.append("collegeCode", this.state.college_code);

      if (this.state.images) {
        axios
          .post(
            process.env.REACT_APP_APIURL +
            `/coordinator/candidate/approved/after/s3`,
            formData
          )
          .then((res) => {
            if (res.status === 200) {
              alert("Candidate Approved successfully.");
              this.setState({ redirect: true, loader: true });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              errorsUpload: "Please Go Back and Try again later...",
            });
          });
      } else {
        alert("please upload candidate form image");
      }
    }
  }

  updateUserData(event) {
    event.preventDefault();
    let formData = new FormData();

    formData.append("yin_id", this.state.yin_id);
    formData.append("first_name", this.state.first_name);
    formData.append("last_name", this.state.last_name);
    formData.append("email", this.state.email);
    formData.append("dob", this.state.dob);
    formData.append("gender", this.state.gender);
    formData.append("address_line_1", this.state.address_line_1);
    formData.append("address_line_2", this.state.address_line_2);
    formData.append("city", this.state.city);
    formData.append("pincode", this.state.pincode);
    formData.append("collegeName", this.state.college_name);
    formData.append("collegeCode", this.state.college_code);

    axios.post(process.env.REACT_APP_APIURL + `/coordinator/user/update/data`, formData)
      .then((res) => {
        if (res.status === 200) {
          alert("User Updated successfully.");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorsUpload: "Please Go Back and Try again later...",
        });
      });
  }

  // for image upload
  onImageChange = (event) => {
    var fileInput = false;

    if (event.target.files && event.target.files[0]) {
      const image = event.target.files[0];
      this.setState({
        modelImageLoader: true,
        isDisabled: true,
      });

      if (event.target.files[0]) {
        fileInput = true;
      }

      if (fileInput) {
        let newFileName = image.name.replace(/\..+$/, "");
        let fileType = image.type;

        // Upload image using uploadImageFromBackend utility
        uploadImageFromBackend(newFileName, fileType, image)
          .then((imageURL) => {
            if (imageURL) {
              this.setState({
                modelImageLoader: false,
                images: imageURL,
                approved_image: imageURL,
                showUploadedForm: true,
                isDisabled: false,
                loader: false,
              });
            } else {
              console.error("Image upload failed.");
              this.setState({
                modelImageLoader: false,
                isDisabled: false,
              });
            }
          })
          .catch((err) => {
            console.error("Error uploading image:", err);
            this.setState({
              modelImageLoader: false,
              isDisabled: false,
            });
          });
      }
    } else {
      console.log("images not selected");
    }
  };


  render() {
    const { year } = this.state;
    const { cities } = this.state;
    if (this.state.redirect) {
      return <Redirect to="/candidateList" />;
      // return <Redirect to={{ pathname: '/edit_candidate', state: { year, cities } }} />;
    }
    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar title="Edit Candidiate" />
          </div>
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink="/candidateList"
              backName="All candidate list"
              currentTabName="Edit Candidate"
              param1={year}
              param2={cities}
            />

            <div className="row mt-4">
              <div style={{
                marginBottom: "50px",
                overflow: "hidden",
              }}
                className="col-lg-10 col-md-10 col-10 paddingAll10 mx-auto ">
                <div className="card rounded shadow shadow-sm">
                  <div className="card-header">
                    <h3 className="mb-0 center">View Candidate</h3>
                  </div>
                  <div className="errorMsg">{this.state.errorsGETDATA}</div>
                  <div className="card-body paddingAll20">
                    <form id="formEditcandidate">

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group  mt-2">
                            <label htmlFor="first_name" className="control-label">
                              Candidate Name
                            </label>
                            <input
                              type="text"
                              id="first_name"
                              name="first_name"
                              className="form-control rounded-1"
                              value={this.state.first_name}
                              onChange={this.handleChange}
                              placeholder="Enter candidate first name"
                            />
                            <div className="errorMsg">
                              {this.state.errors.first_name}
                            </div>
                          </div>

                          {this.state.middle_name ? (
                            <div className="form-group  mt-2">
                              <label
                                htmlFor="middle_name"
                                className="control-label"
                              >
                                Candidate Middle Name
                              </label>
                              <input
                                type="text"
                                id="middle_name"
                                name="middle_name"
                                className="form-control rounded-1"
                                value={this.state.middle_name}
                                onChange={this.handleChange}
                                placeholder="Enter candidate middle name"
                              />
                              <div className="errorMsg">
                                {this.state.errors.middle_name}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group  mt-2">
                            <label htmlFor="last_name" className="control-label">
                              Candidate last Name
                            </label>
                            <input
                              type="text"
                              id="last_name"
                              name="last_name"
                              className="form-control rounded-1"
                              value={this.state.last_name}
                              onChange={this.handleChange}
                              placeholder="Enter candidate last name"
                            />
                            <div className="errorMsg">
                              {this.state.errors.last_name}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group  mt-2">
                            <label htmlFor="mobile" className="control-label">
                              Mobile Number
                            </label>
                            <input
                              type="tel"
                              id="mobile"
                              name="mobile"
                              className="form-control rounded-1"
                              value={this.state.mobile}
                              onChange={this.handleChange}
                              placeholder="Enter mobile numner"
                              readOnly={true}
                            />
                            <div className="errorMsg">
                              {this.state.errors.mobile}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="Email" className="form-group  mt-2">
                            <label
                              htmlFor="Email"
                              id="currentadd"
                              className="control-label"
                            >Email Address
                            </label>
                            <input
                              type="text"
                              id="Email"
                              name="email"
                              value={this.state.email}
                              onChange={this.handleChange}
                              className="form-control"
                              placeholder="abc@gmail.com"
                            />
                            <div className="errorMsg">
                              {this.state.errors.email}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group  mt-2">
                            <label htmlFor="dob" id="dob" className="control-label">
                              Date of Birth{" "}
                            </label>
                            <input
                              type="text"
                              name="dob"
                              value={this.state.dob}
                              onChange={this.handleChange}
                              className="form-control"
                              placeholder="DD-MM-YYYY"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="gender" className="form-group  mt-2">
                            <label
                              htmlFor="gender"
                              id="gender"
                              className="control-label"
                            >
                              Gender{" "}
                            </label>
                            <select
                              id="gender"
                              className="form-select"
                              name="gender"
                              onChange={this.handleChange}
                              required={true}
                            >
                              <option value={this.state.gender}>{this.state.gender}</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="stateDiv" className="form-group mt-4">
                            <label htmlFor="states" className="control-label">
                              Select State
                            </label>
                            <select
                              id="states"
                              className="form-select"
                              name="state"
                              onChange={this.ChangeCity}
                              required={true}
                            >
                              <option value={this.state.state}>
                                {this.state.state === "MH"
                                  ? "Maharashtra"
                                  : "Maharashtra"}
                              </option>
                              <option value="MH">Maharashtra</option>
                              {/* <option value="GA">Goa</option> */}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="stateDiv" className="form-group  mt-4">
                            <label
                              htmlFor="citys"
                              id="cityLabel"
                              className="control-label"
                            >
                              Select City
                            </label>
                            <select
                              id="citys"
                              name="city"
                              className="form-select"
                              onChange={this.showCity}
                              required={true}
                            >
                              <option value={this.state.city}>
                                {this.state.city
                                  ? this.state.city
                                  : "--- choose city---"}
                              </option>
                              {this.state.CityData.map((e, key) => {
                                return (
                                  <option key={key} value={e.countryCode}>
                                    {e.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group  mt-4">
                            <label
                              htmlFor="candidateCollege"
                              className="control-label"
                            >
                              <span style={{ color: "#ff0000" }}>*</span>Candidate
                              college
                            </label>
                            <input
                              type="text"
                              id="candidateCollege"
                              value={this.state.college_name}
                              className="form-control"
                              readOnly={true}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group  mt-4">
                            <label
                              htmlFor="college_name"
                              className="control-label"
                            >
                              Change College
                            </label>

                            <Select
                              options={this.state.dropDownOptCollegeList}
                              onChange={this.onChangeForCollegeListDropDown.bind(
                                this
                              )}
                              Redirect="/view-card"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="Address1" className="form-group mt-4">
                            <label
                              htmlFor="Address1"
                              id="Address1"
                              className="control-label"
                            >
                              Address line 1
                            </label>
                            <input
                              type="text"
                              name="address_line_1"
                              value={this.state.address_line_1}
                              onChange={this.handleChange}
                              className="form-control"
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.Address1}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="Address2" className="form-group mt-4">
                            <label
                              htmlFor="Address2"
                              id="Address1"
                              className="control-label"
                            >
                              Address line 2
                            </label>
                            <input
                              type="text"
                              name="address_line_2"
                              value={this.state.address_line_2}
                              onChange={this.handleChange}
                              className="form-control"
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.Address2}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div id="stateDiv" className="form-group">
                        <label htmlFor="state" className="control-label">Select State</label>
                        <select id="state" name="state" onChange={this.handleChange} className="form-control">
                           <option value={this.state.state}>{this.state.state === "MH" ? "Maharashtra" : "Goa"}</option>
                          <option value="MH">Maharashtra</option>
                        </select>
                        <div className="errorMsg">{this.state.errors.state_name}</div>
                      </div>

                      <div id="cityDiv" className="form-group">
                        <label htmlFor="city" className="control-label">Select City</label>
                        <select id="city" name="city" onChange={this.handleChange} className="form-control">
                          <option value={this.state.city}>{this.state.city}</option>
                          <div className="errorMsg">{this.state.errors.city_name}</div>
                        </select>

                      </div> */}


                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="pincode" className="form-group mt-2">
                            <label
                              htmlFor="pincode"
                              id="pincode"
                              className="control-label"
                            >
                              Pincode
                            </label>
                            <input
                              type="number"
                              name="pincode"
                              value={this.state.pincode}
                              onChange={this.handleChange}
                              className="form-control"
                              placeholder="411035"
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.pincode}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="YIN_ID" className="form-group mt-2">
                            <label
                              htmlFor="YIN_ID"
                              id="YIN_ID"
                              className="control-label"
                            >
                              YIN ID
                            </label>
                            <input
                              type="text"
                              name="YIN_ID"
                              value={this.state.yin_id}
                              className="form-control"
                              disabled
                            ></input>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="college_Id_photo" className="form-group mt-2">
                            <label htmlFor="college_Id_photo" style={{ marginRight: "5px" }}>
                              College ID Photo{" "}
                            </label>
                            {/* <input type="file" id="college_Id_photo" name="college_Id_photo" className="form-control-file"  onChange={this.onImageChange} /> */}
                            <img
                              src={this.state.college_id[0]}
                              width="100"
                              height="60"
                              alt=""
                              onClick={() =>
                                this.isShowPopup(true, this.state.college_id[0])
                              }
                            />
                            <button
                              type="button"
                              id="btnSubmitSignup"
                              className="btn btn-success btn-md m-2"
                              onClick={() => this.isShowImageIdPopup(true)}
                            >
                              Change College ID
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="Goverment_Id_photo" className="form-group mt-2">
                            <label htmlFor="college_Id_photo" style={{ marginRight: "5px" }}>
                              Government ID Photo{" "}
                            </label>
                            <img
                              src={this.state.government_id[0]}
                              width="100"
                              height="60"
                              alt=""
                              onClick={() =>
                                this.isShowPopup(true, this.state.government_id[0])
                              }
                            />
                            <button
                              type="button"
                              id="btnSubmitSignup"
                              className="btn btn-success btn-md m-2"
                              onClick={() => this.isShowGovtIdPopup(true)}
                            >
                              Change Government ID
                            </button>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="Goverment_Id_photo" className="form-group mt-4">
                            <label htmlFor="college_Id_photo">
                              Candidate Nomination form{" "}
                            </label>
                            <a
                              href={this.state.candidate_form[0]}
                              className="btn btn-success btn-md m-2"
                              target="_blank"
                              rel="noreferrer"
                            >
                              View
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="Payment_status" className="form-group mt-4">
                            <label
                              htmlFor="Payment_status"
                              id="Payment_status"
                              className="control-label"
                            >
                              Payment Status
                            </label>
                            <input
                              type="text"
                              name="Payment_status"
                              value={this.state.status}
                              className="form-control"
                              disabled
                            ></input>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        <div id="form_photo" className="form-group mt-4">
                          <label htmlFor="form_photo">Form Photo </label>
                          <input
                            type="file"
                            id="form_photo"
                            name="form_photo"
                            className="form-control-file"
                            onChange={this.onImageChange}
                          />
                          {this.state.modelImageLoader ? <LoaderRounded /> : ""}
                          <div className="errorMsg">
                            Please upload candidate form image
                          </div>
                        </div>

                        <div className="errorMsg">{this.state.errorsGETDATA}</div>
                      </div>

                      <hr />
                      <div>
                        <button
                          type="button"
                          id="btnSubmitSignup"
                          disabled={this.state.isDisabled}
                          className="btn btn-success btn-lg m-2"
                          onClick={this.handleSubmitforapprovedcandodate}
                        >
                          {this.state.loader ? (
                            <LoaderRounded />
                          ) : (
                            "Approved Candidate"
                          )}
                        </button>
                        <button
                          type="button"
                          id="btnSubmitProfile"
                          disabled={this.state.isDisabled}
                          className="btn btn-warning btn-lg m-2"
                          onClick={this.updateUserData}
                        >
                          {this.state.loader ? (
                            <LoaderRounded />
                          ) : (
                            "Save candidate profile"
                          )}
                        </button>
                        <button
                          type="button"
                          id="btnSubmitSignup"
                          disabled={this.state.isDisabled}
                          className="btn btn-danger btn-lg  m-2 "
                          onClick={this.handleReject}
                        >
                          {this.state.loader ? (
                            <LoaderRounded />
                          ) : (
                            "Reject Candidate"
                          )}
                        </button>
                        <button
                          type="button"
                          id="btnSubmitSignup"
                          disabled={this.state.isDisabled}
                          className="btn btn-danger btn-lg  m-2 "
                          onClick={this.handlecreatepdf}
                        >
                          {this.state.pdfdownload ? (
                            <LoaderRounded />
                          ) : (
                            "CreateNewPdf"
                          )}
                        </button>
                      </div>
                      {/* <button type="button" id="btnSubmitSignup" disabled={this.state.isDisabled} className="btn btn-danger btn-lg  m-2" onClick={this.handleLDPStart}>LDP Start</button>
                      <button type="button" id="btnSubmitSignup" disabled={this.state.isDisabled} className="btn btn-danger btn-lg  m-2 ">LDP Stop</button> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ImagePopup
          showModalPopup={this.state.showModalPopup}
          collegeId_card="College Id Card"
          imageUrl={this.state.showingImage}
          onPopupCloseMain={this.isClosePopup.bind(this)}
        ></ImagePopup>

        <ImageIdPopup
          showimageIdPopup={this.state.showimageIdPopup}
          collegeId_card="Change College Id Card"
          yin_id={this.state.yin_id}
          imageUrl={this.state.college_id[0]}
          onPopupCloseMain={this.isClosePopup.bind(this)}
        ></ImageIdPopup>

        <ImageGovtIdPopup
          showGovtIdPopup={this.state.showGovtIdPopup}
          collegeId_card="Change Government Id Card"
          yin_id={this.state.yin_id}
          imageUrl={this.state.college_id[0]}
          onPopupCloseMain={this.isClosePopup.bind(this)}
        ></ImageGovtIdPopup>
      </>
    );
  }
}

export default Editcandidate;
