import React, { Component } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import S3 from "react-aws-s3";
import moment from "moment";
import Compressor from "compressorjs";
import LoaderRounded from "./LoaderRounded";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import { uploadImageFromBackend } from "../Utils/uploadImage";
class AddnewIssue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issue_title: "",
      issue_description: "",
      issue_full_description: "",
      issue_images: [],
      issue_tags: [],
      issue_types: "",
      forum_id: this.props.selected_forum ? this.props.selected_forum : "",
      is_published: "",
      issue_created_at: "",
      issue_ended_at: "",
      issue_start_time: "",
      issue_end_time: "",
      MemberData: [],
      issue_member_details: [],
      memberlist: "",
      member: "",
      loader: false,

      initailTag: "",
      redirect: false,
      errors: {},
    };
    this.uploadImageToS3 = this.uploadImageToS3.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    // this.getMember = this.getMember.bind(this);
    this.selectMember = this.selectMember.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getMember();
  }

  getMember() {
    axios
      .get(
        process.env.REACT_APP_TALK_API +
        `/forum/get/forum/member-details/${this.props.selected_forum}`
      )
      .then((response) => {
        if (response.data) {
          this.setState({
            MemberData: response.data,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }

  handleFormChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  addNewImage(newImage) {
    let img_arr = this.state.issue_images;
    img_arr.push(newImage);
    this.setState({
      issue_images: img_arr,
    });
  }

  removeImageURL(id) {
    let img_arr = this.state.issue_images;
    img_arr = img_arr.filter((img) => img.id !== id);
    this.setState({
      issue_images: img_arr,
    });
  }
  removeMember(id) {
    let tag_arr = this.state.issue_member_details;
    tag_arr = tag_arr.filter((tag) => tag !== id);

    this.setState({
      issue_member_details: tag_arr,
    });
  }


  // for image upload
  uploadImageToS3(event) {
    const image = event.target.files[0];
    if (!image) return;

    if (image.type === "image/png" || image.type === "image/jpeg") {
      new Compressor(image, {
        quality: 0.6,
        success: async (compressedResult) => {
          try {
            let newFileName = image.name.replace(/\..+$/, "");
            let fileType = image.type;

            // Use the uploadImageFromBackend utility
            const imageURL = await uploadImageFromBackend(newFileName, fileType, compressedResult);

            if (imageURL) {
              this.addNewImage({
                type: "IMAGE",
                url: imageURL,
                name: newFileName,
                id: Math.floor(Math.random() * 9999999999),
              });
            } else {
              console.error("Image upload failed.");
            }
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        },
        error: (err) => {
          console.error("Compression error:", err.message);
        },
      });
    } else {
      console.error("Invalid file type. Please upload a PNG or JPEG image.");
    }
  }


  handleFormValidation = () => {
    const {
      issue_title,
      issue_description,
      issue_id,
      forum_id,
      is_published,

      //following field having no need of validation
      issue_images,
      issue_types,
      issue_full_description,
      issue_created_at,
      issue_ended_at,
      issue_start_time,
      issue_end_time,
      issue_member_details,
      issue_tags,
    } = this.state;
    let errors = {};
    let formIsValid = true;

    //empty college name
    let arr = [];
    if (!issue_title) {
      errors["issue_title"] = "*Give Your Answer.";
      formIsValid = false;
      arr.push({
        field: "issue_title",
      });
    }

    if (!is_published) {
      formIsValid = false;
      errors["is_published"] = "*Give Your Answer.";
      arr.push({
        field: "is_published",
      });
    }

    // if (!issue_full_description) {
    //   formIsValid = false;
    //   errors["issue_full_description"] = "*Give Your Answer.";
    //   arr.push({
    //     field: "issue_full_description",
    //   });
    // }
    if (!issue_description) {
      formIsValid = false;
      errors["issue_description"] = "*Give Your Answer.";
      arr.push({
        field: "issue_description",
      });
    }

    if (!issue_types) {
      formIsValid = false;
      errors["issue_types"] = "*Give Your Answer.";
      arr.push({
        field: "issue_types",
      });
    }
    // if (issue_tags.length <= 0) {
    //   formIsValid = false;
    //   errors["initailTag"] = "*Give Your Answer.";
    //   arr.push({
    //     field: "initailTag",
    //   });
    // }
    // if (!issue_created_at) {
    //   formIsValid = false;
    //   errors["issue_created_at"] = "*Give Your Answer.";
    //   arr.push({
    //     field: "issue_created_at",
    //   });
    // }
    // if (!issue_ended_at) {
    //   formIsValid = false;
    //   errors["issue_ended_at"] = "*Give Your Answer.";
    //   arr.push({
    //     field: "issue_ended_at",
    //   });
    // }
    // if (!issue_start_time) {
    //   formIsValid = false;
    //   errors["issue_start_time"] = "*Give Your Answer.";
    //   arr.push({
    //     field: "issue_start_time",
    //   });
    // }
    // if (!issue_end_time) {
    //   formIsValid = false;
    //   errors["issue_end_time"] = "*Give Your Answer.";
    //   arr.push({
    //     field: "issue_end_time",
    //   });
    // }
    // if (issue_member_details.length <= 0) {
    //   formIsValid = false;
    //   errors["issue_member_details"] = "*Give Your Answer.";
    //   arr.push({
    //     field: "issue_member_details",
    //   });
    // }
    if (arr.length > 0) {
      const firstErrorField = document.querySelector(
        `[name="${arr[0].field}"]`
      );
      if (firstErrorField && firstErrorField.focus) {
        firstErrorField.focus();
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.handleFormValidation()) {
      const Issue = {
        ...this.state,
      };

      let statesSet = this;
      await axios
        .post(process.env.REACT_APP_TALK_API + "/issue/create", Issue)
        .then((res) => {
          if (res) {
            alert("Issue addded successfully");
            statesSet.setState({
              changeToView: true,
              redirect: true,
              loader: true,
            });
          }
        });
    } else {
      this.handleFormValidation();
    }
  };

  selectMember = (e) => {
    let arr = this.state.issue_member_details;

    arr.push({
      first_name: JSON.parse(e.target.value).first_name,
      last_name: JSON.parse(e.target.value).last_name,
      designation: JSON.parse(e.target.value).designation,
      yin_id: JSON.parse(e.target.value).yin_id,
    });

    this.setState({
      issue_member_details: arr,
    });
  };
  addNewTag() {
    if (this.state.initailTag.length > 0) {
      let tag_arr = this.state.issue_tags;
      tag_arr.push(this.state.initailTag);

      this.setState({
        issue_tags: tag_arr,
        initailTag: "",
      });
    }
  }
  removeTag(id) {
    let tag_arr = this.state.issue_tags;
    tag_arr = tag_arr.filter((tag) => tag !== id);

    this.setState({
      issue_tags: tag_arr,
    });
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to="/Issue-List" />;
    }
    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <BackAndBreadCrmOneParent
            backLink="/Issue-List"
            backName="Issue-List"
            currentTabName="Add New Issue"
          />
          <div className="container">
            <div className="row mt-4">
              <div
                style={{
                  heigh: "800vh",
                  overflow: "hidden",
                }}
                className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto "
              >
                <div className="card rounded shadow shadow-sm ">
                  <div className="card-header">
                    <h3 className="mb-0 center">Add New Issue</h3>
                  </div>
                  <div className="card-body">
                    <form
                      id="candidate"
                      method="post"
                      onSubmit={this.handleSubmit}
                      encType="multipart/form-data"
                    >
                      <div className="form-group">
                        <label htmlFor="issue_title" className="control-label">
                          <span style={{ color: "#ff0000" }}>*</span>Issue Title
                        </label>
                        <input
                          id="issue_title"
                          type="text"
                          className="form-control"
                          name="issue_title"
                          value={this.state.issue_title}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        ></input>
                        <div className="errorMsg">
                          {this.state.errors.issue_title}
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="issue_description"
                          className="control-label"
                        >
                          <span style={{ color: "#ff0000" }}>*</span>Issue
                          Description
                        </label>
                        <input
                          id="issue_description"
                          type="text"
                          className="form-control"
                          name="issue_description"
                          value={this.state.issue_description}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        ></input>
                        <div className="errorMsg">
                          {this.state.errors.issue_description}
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="issue_full_description"
                          className="control-label"
                        >
                          Issue Full Description
                        </label>
                        <input
                          id="issue_full_description"
                          type="text"
                          className="form-control"
                          name="issue_full_description"
                          value={this.state.issue_full_description}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        ></input>
                        <div className="errorMsg">
                          {this.state.errors.issue_full_description}
                        </div>
                      </div>
                      <div className="textOnInputs">
                        <div className="col-md-12 mt-3">
                          <label htmlFor="image">
                            <span style={{ color: "#ff0000" }}>*</span>Issue
                            Image
                          </label>
                          <input
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            id="image"
                            name="college_id"
                            className="form-control-file"
                            onChange={this.uploadImageToS3}
                          />
                          {this.state.issue_images.length > 0
                            ? this.state.issue_images.map((img) => {
                              return (
                                <div
                                  className="card"
                                  style={{ margin: 10, width: 200 }}
                                >
                                  <img
                                    src={img.url}
                                    key={img.url}
                                    className="card-img-top"
                                    alt={img.name}
                                    width="100px"
                                    height="100px"
                                  />
                                  <button
                                    onClick={() => {
                                      this.removeImageURL(img.id);
                                    }}
                                    type="button"
                                    className="btn btn-danger"
                                  >
                                    Remove
                                  </button>
                                </div>

                                // <img
                                //   id="image"
                                //   src={item.url}
                                //   alt="No Image"
                                //   width="100px"
                                //   height="80px"
                                // />
                              );
                            })
                            : ""}
                          <div className="errorMsg">
                            {this.state.errors.issue_images}
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="issue_types" className="control-label">
                          <span style={{ color: "#ff0000" }}>*</span>Issue Types
                        </label>
                        <select
                          id="issue_types"
                          className="form-select"
                          name="issue_types"
                          value={this.state.issue_types}
                          onChange={this.handleFormChange}
                          disabled={
                            this.state.status === false ? "disabled" : null
                          }
                        >
                          <option value="">--Choose--</option>
                          <option value="LIVE">LIVE</option>
                          <option value="COMPLETED">COMPLETED</option>
                          <option value="DROPPED">DROPPED</option>
                          <option value="EXTERNAL">EXTERNAL</option>
                        </select>
                        <div className="errorMsg">
                          {this.state.errors.issue_types}
                        </div>
                      </div>
                      <div className="textOnInputs">
                        <div className="col-md-12 mt-3">
                          <label htmlFor="initailTag">
                            <span style={{ color: "#ff0000" }}>*</span>
                            issue_tags
                          </label>

                          <div class="input-group mb-3">
                            <input
                              id="initailTag"
                              type="text"
                              className="form-control"
                              name="initailTag"
                              value={this.state.initailTag}
                              onChange={this.handleFormChange}
                              placeholder="Type here"
                            ></input>
                            <span
                              onClick={() => {
                                this.addNewTag();
                              }}
                              className="input-group-text"
                            >
                              Enter
                            </span>
                          </div>

                          {/* <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                id="image"
                                name="college_id"
                                className="form-control-file"
                                onChange={this.uploadImageToS3}
                              /> */}

                          <div className="errorMsg">
                            {this.state.errors.initailTag}
                          </div>
                        </div>

                        {this.state.issue_tags.length === 0
                          ? null
                          : this.state.issue_tags.map((tag) => {
                            return (
                              <>
                                <div
                                  className="card"
                                  style={{ margin: 10, width: 200 }}
                                >
                                  <div>{tag}</div>
                                  <button
                                    onClick={() => {
                                      this.removeTag(tag);
                                    }}
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </>
                            );
                          })}
                      </div>
                      <div className="form-group">
                        <label htmlFor="Is Published" className="control-label">
                          <span style={{ color: "#ff0000" }}>*</span>Is
                          Published
                        </label>
                        <select
                          id="Is Published"
                          className="form-select"
                          name="is_published"
                          value={this.state.is_published}
                          onChange={this.handleFormChange}
                          disabled={
                            this.state.status === false ? "disabled" : null
                          }
                        >
                          <option value="">--Choose--</option>
                          <option value="true">YES</option>
                          <option value="false">NO</option>
                        </select>
                        <div className="errorMsg">
                          {this.state.errors.is_published}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label-caption" htmlFor="Forum Id">
                          <span style={{ color: "#ff0000" }}>*</span>Form Id
                        </label>
                        <input
                          type="text"
                          id="Forum Id"
                          className="form-control"
                          name="forum_id"
                          value={this.state.forum_id}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                          disabled
                        />
                        <div className="errorMsg">
                          {this.state.errors.forum_id}
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="label-caption"
                          htmlFor="issue created date"
                        >
                          <span style={{ color: "#ff0000" }}>*</span>Issue Start
                          Date
                        </label>
                        <input
                          type="date"
                          id="issue created date"
                          className="form-control"
                          name="issue_created_at"
                          value={moment(this.state.issue_created_at).format(
                            "yyyy-MM-DD"
                          )}
                          onChange={this.handleFormChange}
                        />
                        <div className="errorMsg">
                          {this.state.errors.issue_created_at}
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="label-caption"
                          htmlFor="issue end date"
                        >
                          <span style={{ color: "#ff0000" }}>*</span>Issue End
                          Date
                        </label>
                        <input
                          type="date"
                          id="issue end date"
                          className="form-control"
                          name="issue_ended_at"
                          value={moment(this.state.issue_ended_at).format(
                            "yyyy-MM-DD"
                          )}
                          onChange={this.handleFormChange}
                        />
                        <div className="errorMsg">
                          {this.state.errors.issue_ended_at}
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          className="label-caption"
                          htmlFor="Issue Start Time"
                        >
                          <span style={{ color: "#ff0000" }}>*</span>Issue Start
                          Time
                        </label>
                        <input
                          type="time"
                          id="Issue Start Time"
                          className="form-control"
                          name="issue_start_time"
                          value={this.state.issue_start_time}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        />
                        <div className="errorMsg">
                          {this.state.errors.issue_start_time}
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="label-caption" htmlFor="issueEndTime">
                          <span style={{ color: "#ff0000" }}>*</span>Issue End
                          Time
                        </label>
                        <input
                          type="time"
                          id="issueEndTime"
                          className="form-control"
                          name="issue_end_time"
                          value={this.state.issue_end_time}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        />
                        <div className="errorMsg">
                          {this.state.errors.issue_end_time}
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="member" htmlFor="issueEndTime">
                          Member Details
                        </label>
                        <div>
                          <select
                            type="button"
                            className="mx-2 btn btn-secondary btn-md dropdown-toggle"
                            onChange={this.selectMember}
                            id="Member"
                          >
                            <option value={null}> --Member-- </option>
                            {this.state.MemberData.length > 0 &&
                              this.state.MemberData.map((e, key) => {
                                return (
                                  <option value={JSON.stringify(e)}>
                                    {e.first_name.toString() +
                                      "      " +
                                      e.last_name.toString()}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        {this.state.issue_member_details.length === 0 ? null : (
                          <div>
                            {this.state.issue_member_details.map((tag) => {
                              return (
                                <>
                                  <div
                                    className="card"
                                    style={{
                                      margin: 10,
                                      width: 200,
                                    }}
                                  >
                                    <div>{tag.yin_id}</div>
                                    <button
                                      onClick={() => {
                                        this.removeMember(tag);
                                      }}
                                      type="button"
                                      className="btn btn-danger btn-sm"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        )}
                        <div className="errorMsg">
                          {this.state.errors.issue_member_details}
                        </div>
                      </div>

                      <div className="form-group">
                        <button
                          type="submit"
                          id="btnSubmitSignup"
                          className="btn btn-primary btn-lg btn-block mb-2 mt-4"
                          style={{ margin: "4px" }}
                        >
                          {this.state.loader ? <LoaderRounded /> : "Add"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    selected_forum: state.PersistReducer.UserData.selected_forum,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddnewIssue);
