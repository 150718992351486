import React, { Component } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import S3 from "react-aws-s3";
// import moment from "moment";
import Compressor from "compressorjs";
import LoaderRounded from "./LoaderRounded";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { uploadImageFromBackend } from "../Utils/uploadImage";

class Position extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issue_id: this.props.selected_activity.issue_id
        ? this.props.selected_activity.issue_id
        : "",

      forum_id: this.props.selected_forum ? this.props.selected_forum : "",

      activity_status: "",
      // activity_member_details: [
      //   { designation: "member", yin_id: "MHPC000012" },
      //   { designation: "member", yin_id: "MHPC0000123" },
      //   { designation: "member", yin_id: "MHPC00001234" },
      // ],

      activity_title: "",
      activity_description: "",
      activity_tags: [],
      activity_images: [],
      activity_start_time: "",
      activity_end_time: "",
      activity_member_details: [],
      MemberData: [],
      memberlist: "",
      member: "",
      is_published: "",
      redirect: false,
      errors: {},
      loading: false,
      loader: false,
      initailTag: "",
    };
    this.uploadImageToS3 = this.uploadImageToS3.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.selectMember = this.selectMember.bind(this);
    this.addNewTag = this.addNewTag.bind(this);
  }

  handleFormChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleFormValidation = () => {
    const {
      activity_tags,
      activity_title,
      activity_description,
      activity_end_time,
      activity_start_time,
      is_published,
      activity_images,
      activity_status,
    } = this.state;
    let errors = {};
    let formIsValid = true;

    //empty college name
    let arr = [];
    if (!activity_title) {
      errors["activity_title"] = "*Give Your Answer.";
      formIsValid = false;
      arr.push({
        field: "activity_title",
      });
    }
    //  else if (!activity_title.match(/^[a-zA-Z ]*$/)) {
    //   formIsValid = false;
    //   errors["activity_title"] = "*Please enter alphabet characters only.";
    //   arr.push({
    //     field: "activity_title",
    //   });
    // }

    if (!activity_description) {
      formIsValid = false;
      errors["activity_description"] = "*Give Your Answer.";
      arr.push({
        field: "activity_description",
      });
    }

    if (!activity_start_time) {
      formIsValid = false;
      errors["activity_start_time"] = "*Give Your Answer.";
      arr.push({
        field: "activity_start_time",
      });
    }
    if (!activity_end_time) {
      formIsValid = false;
      errors["activity_end_time"] = "*Give Your Answer.";
      arr.push({
        field: "activity_end_time",
      });
    }
    if (!is_published) {
      formIsValid = false;
      errors["is_published"] = "*Give Your Answer.";
      arr.push({
        field: "is_published",
      });
    }
    if (!activity_status) {
      errors["activity_status"] = "*Give Your Answer.";
      formIsValid = false;
      arr.push({
        field: "activity_status",
      });
    }
    if (activity_tags.length <= 0) {
      formIsValid = false;
      errors["initailTag"] = "*Give Your Answer.";
      arr.push({
        field: "initailTag",
      });
    }
    if (activity_images.length <= 0) {
      formIsValid = false;
      errors["college_id"] = "*Give Your Answer.";
      arr.push({
        field: "college_id",
      });
    }
    if (arr.length > 0) {
      const firstErrorField = document.querySelector(
        `[name="${arr[0].field}"]`
      );
      if (firstErrorField && firstErrorField.focus) {
        firstErrorField.focus();
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };
  UNSAFE_componentWillMount() {
    this.getMember();
  }

  getMember() {
    axios
      .get(
        process.env.REACT_APP_TALK_API +
        `/issue/get/issue/member-details/${this.props.selected_activity.issue_id}`
      )
      .then((response) => {
        if (response?.data?.length > 0 && Array?.isArray(response.data)) {
          this.setState({
            MemberData: response?.data,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }

  addNewImage(newImage) {
    let img_arr = this.state.activity_images;
    img_arr.push(newImage);

    this.setState({
      activity_images: img_arr,
    });
  }
  addNewTag() {
    if (this.state.initailTag.length > 0) {
      let tag_arr = this.state.activity_tags;
      tag_arr.push(this.state.initailTag);

      this.setState({
        activity_tags: tag_arr,
        initailTag: "",
      });
    }
  }
  removeTag(id) {
    let tag_arr = this.state.activity_tags;
    tag_arr = tag_arr.filter((tag) => tag !== id);

    this.setState({
      activity_tags: tag_arr,
    });
  }
  removeImageURL(id) {
    let img_arr = this.state.activity_images;
    img_arr = img_arr.filter((img) => img.id !== id);

    this.setState({
      activity_images: img_arr,
    });
  }

  // upload image
  uploadImageToS3(event) {
    const image = event.target.files[0];
    if (!image) return;

    if (image.type === "image/png" || image.type === "image/jpeg") {
      new Compressor(image, {
        quality: 0.6,
        success: async (compressedResult) => {
          try {
            let newFileName = image.name.replace(/\..+$/, "");
            let fileType = image.type;

            // Use the uploadImageFromBackend utility
            const imageURL = await uploadImageFromBackend(newFileName, fileType, compressedResult);

            if (imageURL) {
              this.addNewImage({
                type: "IMAGE",
                url: imageURL,
                name: newFileName,
                id: Math.floor(Math.random() * 9999999999),
              });
            } else {
              console.error("Image upload failed.");
            }
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        },
        error: (err) => {
          console.error("Compression error:", err.message);
        },
      });
    } else {
      console.error("Invalid file type. Please upload a PNG or JPEG image.");
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.handleFormValidation()) {
      const Issue = {
        ...this.state,
      };

      let statesSet = this;

      await axios
        .post(process.env.REACT_APP_TALK_API + "/activity/create", Issue)
        .then((res) => {
          if (res.data) {
            alert("Activity Added successfully");
            statesSet.setState({
              changeToView: true,
              redirect: true,
              loader: true,
            });
          }
        });
    } else {
      this.handleFormValidation();
    }
  };

  selectMember = (e) => {
    let arr = this.state.activity_member_details;
    arr.push({
      first_name: JSON.parse(e.target.value).first_name,
      last_name: JSON.parse(e.target.value).last_name,
      designation: JSON.parse(e.target.value).designation,
      yin_id: JSON.parse(e.target.value).yin_id,
    });
    this.setState({
      activity_member_details: arr,
    });
  };
  removeMember(id) {
    let tag_arr = this.state.activity_member_details;
    tag_arr = tag_arr.filter((tag) => tag !== id);

    this.setState({
      activity_member_details: tag_arr,
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/Activity-List" />;
    }
    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />
            <div className="main">
              <div className="topbar">
                <Navbar />
              </div>
              {/* <div>
                <select
                  type="button"
                  className="mx-2 btn btn-secondary btn-md dropdown-toggle"
                  onChange={this.selectMember}
                  id="Member"
                  style={{ float: "right", width: "120px", marginTop: "10px" }}
                >
                  <option value={null}> --Member-- </option>
                  {this.state.MemberData.map((e, key) => {
                    return (
                      <option key={key} value={JSON.stringify(e)}>
                        {e.first_name.toString() + e.last_name.toString()}{" "}
                      </option>
                    );
                  })}
                </select>
              </div> */}
              <div className="container">
                <BackAndBreadCrmOneParent
                  backLink="/Activity-List"
                  backName="ActivityList"
                  currentTabName="Add new Activity"
                />

                <div className="row mt-4">
                  <div
                    style={{
                      heigh: "800vh",
                      overflow: "hidden",
                    }}
                    className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto "
                  >
                    <div className="card rounded shadow shadow-sm ">
                      <div className="card-header">
                        <h3 className="mb-0 center">Add New Activity</h3>
                      </div>
                      <div className="card-body">
                        <form
                          id="candidate"
                          method="post"
                          onSubmit={this.handleSubmit}
                          encType="multipart/form-data"
                        >
                          <div className="form-group">
                            <label
                              htmlFor="activity_title"
                              className="control-label"
                            >
                              <span style={{ color: "#ff0000" }}>*</span>Issue
                              Title
                            </label>
                            <input
                              id="activity_title"
                              type="text"
                              className="form-control"
                              name="activity_title"
                              value={this.state.activity_title}
                              onChange={this.handleFormChange}
                              placeholder="Type here"
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.activity_title}
                            </div>
                          </div>
                          <br />
                          <hr className="mx-n3" />
                          <div className="form-group">
                            <label
                              htmlFor="activitydescription"
                              className="control-label"
                            >
                              <span style={{ color: "#ff0000" }}>*</span>
                              Activity Description
                            </label>
                            <input
                              id="activitydescription"
                              type="text"
                              className="form-control"
                              name="activity_description"
                              value={this.state.activity_description}
                              onChange={this.handleFormChange}
                              placeholder="Type here"
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.activity_description}
                            </div>
                          </div>
                          <br />
                          <hr className="mx-n3" />
                          <div className="textOnInputs">
                            <div className="col-md-12 mt-3">
                              <label htmlFor="image">
                                <span style={{ color: "#ff0000" }}>*</span>
                                Promotional Campaign Photo
                              </label>

                              <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                id="image"
                                name="college_id"
                                className="form-control-file"
                                onChange={this.uploadImageToS3}
                              />
                              {/* {this.state. activity_images ? <img id="image" src={this.state. activity_images} alt="" width="200px" height="80px" /> : ""} */}
                              <div className="errorMsg">
                                {this.state.errors.college_id}
                              </div>
                            </div>

                            {this.state.activity_images.length === 0
                              ? null
                              : this.state.activity_images.map((img) => {
                                return (
                                  <>
                                    <div
                                      className="card"
                                      style={{ margin: 10, width: 200 }}
                                    >
                                      <img
                                        src={img.url}
                                        key={img.url}
                                        className="card-img-top"
                                        alt={img.name}
                                        width="100px"
                                        height="100px"
                                      />
                                      <button
                                        onClick={() => {
                                          this.removeImageURL(img.id);
                                        }}
                                        type="button"
                                        className="btn btn-danger"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                          </div>

                          <hr className="mx-n3" />
                          <div className="form-group">
                            <label
                              htmlFor="Is Published"
                              className="control-label"
                            >
                              <span style={{ color: "#ff0000" }}>*</span>Is
                              Published
                            </label>
                            <select
                              id="Is Published"
                              className="form-select"
                              name="is_published"
                              value={this.state.is_published}
                              onChange={this.handleFormChange}
                              disabled={
                                this.state.status === false ? "disabled" : null
                              }
                            >
                              <option value="">--Choose--</option>
                              <option value={true}>YES</option>
                              <option value={false}>NO</option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.is_published}
                            </div>
                          </div>
                          <br />
                          <hr className="mx-n3" />
                          <div className="form-group">
                            <label
                              htmlFor="activity status"
                              className="control-label"
                            >
                              <span style={{ color: "#ff0000" }}>*</span>Is
                              activity status
                            </label>
                            <select
                              id="Is Published"
                              className="form-select"
                              name="activity_status"
                              value={this.state.activity_status}
                              onChange={this.handleFormChange}
                              disabled={
                                this.state.status === false ? "disabled" : null
                              }
                            >
                              <option value="">--Choose--</option>
                              <option value="PENDING">PENDING</option>
                              <option value="COMPLETED">COMPLETED</option>
                              <option value="ABANDONED">ABANDONED</option>
                              <option value="ASK_FOR_HELP">ASK_FOR_HELP</option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.activity_status}
                            </div>
                          </div>
                          <br />
                          <hr className="mx-n3" />
                          <div className="textOnInputs">
                            <div className="col-md-12 mt-3">
                              <label htmlFor="initailTag">
                                <span style={{ color: "#ff0000" }}>*</span>
                                activity_tags
                              </label>

                              <div class="input-group mb-3">
                                <input
                                  id="initailTag"
                                  type="text"
                                  className="form-control"
                                  name="initailTag"
                                  value={this.state.initailTag}
                                  onChange={this.handleFormChange}
                                  placeholder="Type here"
                                ></input>
                                <span
                                  onClick={() => {
                                    this.addNewTag();
                                  }}
                                  className="input-group-text"
                                >
                                  Enter
                                </span>
                              </div>

                              {/* <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                id="image"
                                name="college_id"
                                className="form-control-file"
                                onChange={this.uploadImageToS3}
                              /> */}

                              <div className="errorMsg">
                                {this.state.errors.initailTag}
                              </div>
                            </div>

                            {this.state.activity_tags.length === 0
                              ? null
                              : this.state.activity_tags.map((tag) => {
                                return (
                                  <>
                                    <div
                                      className="card"
                                      style={{ margin: 10, width: 200 }}
                                    >
                                      <div>{tag}</div>
                                      <button
                                        onClick={() => {
                                          this.removeTag(tag);
                                        }}
                                        type="button"
                                        className="btn btn-danger btn-sm"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                          <br />
                          <hr className="mx-n3" />
                          <div className="form-group">
                            <label
                              htmlFor="activity start time"
                              className="control-label"
                            >
                              <span style={{ color: "#ff0000" }}>*</span>
                              Activity Start Time
                            </label>
                            <input
                              id="activationimages"
                              type="time"
                              className="form-control"
                              name="activity_start_time"
                              value={this.state.activity_start_time}
                              onChange={this.handleFormChange}
                              placeholder="Type here"
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.activity_start_time}
                            </div>
                          </div>
                          <br />
                          <hr className="mx-n3" />
                          <div className="form-group">
                            <label
                              htmlFor="activity end time"
                              className="control-label"
                            >
                              {" "}
                              <span style={{ color: "#ff0000" }}>*</span>
                              Activity End Time
                            </label>
                            <input
                              id="activity end time"
                              type="time"
                              className="form-control"
                              name="activity_end_time"
                              value={this.state.activity_end_time}
                              onChange={this.handleFormChange}
                              placeholder="Type here"
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.activity_end_time}
                            </div>
                          </div>
                          <br />
                          <hr className="mx-n3" />
                          <div className="form-group">
                            <label className="member" htmlFor="issueEndTime">
                              Member Details
                            </label>
                            <div>
                              <select
                                type="button"
                                className="mx-2 btn btn-secondary btn-md dropdown-toggle"
                                onChange={this.selectMember}
                                id="Member"
                              >
                                <option value={null}> --Member-- </option>
                                {this.state.MemberData.length > 0 &&
                                  this.state.MemberData.map((e, key) => {
                                    return (
                                      <option value={JSON.stringify(e)}>
                                        {e?.first_name?.toString() +
                                          "      " +
                                          e?.last_name?.toString()}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            {this.state.activity_member_details.length ===
                              0 ? null : (
                              <div>
                                {this.state.activity_member_details.map(
                                  (tag) => {
                                    return (
                                      <>
                                        <div
                                          className="card"
                                          style={{
                                            margin: 10,
                                            width: 200,
                                          }}
                                        >
                                          <div>{tag.yin_id}</div>
                                          <button
                                            onClick={() => {
                                              this.removeMember(tag);
                                            }}
                                            type="button"
                                            className="btn btn-danger btn-sm"
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            )}
                            <div className="errorMsg">
                              {this.state.errors.issue_member_details}
                            </div>
                          </div>
                          <div className="form-group">
                            <button
                              type="submit"
                              id="btnSubmitSignup"
                              className="btn btn-primary btn-lg btn-block mb-2 mt-4"
                              style={{ margin: "4px" }}
                            >
                              {this.state.loader ? <LoaderRounded /> : "Add"}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <button
              className=" reaload btn  btn-success btn-sm "
              onClick={() => window.location.reload(true)}
            >
              Click to reload!
            </button> */}
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    selected_forum: state.PersistReducer.UserData.selected_forum,
    selected_activity: state.PersistReducer.UserData.selected_activity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Position);
