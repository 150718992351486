import React, { Component } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import S3 from "react-aws-s3";
import Compressor from "compressorjs";
import { Link } from "react-router-dom";
import LoaderRounded from "./LoaderRounded";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import { uploadImageFromBackend } from "../Utils/uploadImage";

export default class AddLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event_address: "",
      event_address2: "",
      State: "",
      city: "",
      state: "",
      cities: "",
      event: localStorage.getItem("Eventid"),
      event_pincode: "",
      event_latitude: "",
      event_longitude: "",
      latitude: "",
      longitude: "",
      promotion_banner_images: "",
      is_navigation_added: "",
      errors: {},
      loader: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
    this.uploadImageToS3 = this.uploadImageToS3.bind(this);
  }
  handleFormChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleFormValidation = () => {
    const {
      event_address,
      event_address2,
      event_latitude,
      event_longitude,
      event_pincode,
    } = this.state;
    let errors = {};
    let formIsValid = true;

    if (!event_address) {
      formIsValid = false;
      errors["event_address"] = "*Give Your Answer.";
    }
    if (!event_address2) {
      formIsValid = false;
      errors["event_address2"] = "*Give Your Answer.";
    }
    if (!event_latitude) {
      formIsValid = false;
      errors["event_latitude"] = "*Give Your Answer.";
    }

    if (!event_longitude) {
      formIsValid = false;
      errors["event_longitude"] = "*Give Your Answer.";
    }
    if (!event_pincode) {
      formIsValid = false;
      errors["event_pincode"] = "*Give Your Answer.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  //code for to get states lists
  componentDidMount() {
    this.getDatabyeventid();
    this.getBannerImagesbyeventid();
  }

  getDatabyeventid() {
    let eventid = this.state.event;

    let currentComponent = this;

    axios
      .get(process.env.REACT_APP_TALK_API + "/event/list/event/location/" + eventid)
      .then((res) => {
        let responses = res.data.data;

        currentComponent.setState({
          // redirect:true,
          ...responses,
          // event_title:responses [0].event_title,
        });
      });
  }

  getBannerImagesbyeventid() {
    let eventid = this.state.event;

    let currentComponent = this;

    axios
      .get(process.env.REACT_APP_TALK_API + "/event/list/event/location/" + eventid)
      .then((res) => {
        let responses = res.data.data;

        currentComponent.setState({
          // redirect:true,
          loader: false,
          promotion_banner_images: responses?.promotion_banner_images,
          // event_title:responses[0].event_title,
        });
      });
  }

  addNewImage(newImage) {
    let img_arr = this.state.promotion_banner_images;
    img_arr.push(newImage);
    this.setState({
      promotion_banner_images: img_arr,
    });
  }

  removeImageURL(id) {
    let img_arr = this.state.promotion_banner_images;
    img_arr = img_arr.filter((img) => img.id !== id);
    this.setState({
      promotion_banner_images: img_arr,
    });
  }

  // for upload image
  uploadImageToS3(event) {
    const image = event.target.files[0];
    if (!image) return;

    if (image.type === "image/png" || image.type === "image/jpeg") {
      new Compressor(image, {
        quality: 0.6,
        success: async (compressedResult) => {
          try {
            const newFileName = image.name.replace(/\..+$/, "");
            const fileType = image.type;

            // Use the uploadImageFromBackend utility for the upload process
            const imageURL = await uploadImageFromBackend(newFileName, fileType, compressedResult);

            if (imageURL) {
              this.addNewImage({
                type: "IMAGE",
                url: imageURL,
                name: newFileName,
                id: Math.floor(Math.random() * 9999999999),
              });
            } else {
              console.error("Image upload failed.");
            }
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        },
        error: (err) => {
          console.error("Compression error:", err.message);
        },
      });
    } else {
      console.error("Invalid file type. Please upload a PNG or JPEG image.");
    }
  }


  handleSubmitImage = async (event) => {
    event.preventDefault();

    const Image = {
      promotion_banner_images: this.state.promotion_banner_images,
    };

    let statesSet = this;

    await axios
      .post(process.env.REACT_APP_TALK_API + "/event/add/banner/images", Image)
      .then((res) => {

        if (res.status === 200) {
          alert("Image Added successfully");
          statesSet.setState({
            changeToView: true,
            redirect: true,
          });
        }
      });
  };

  render() {
    if (this.state.is_navigation_added === false) {
      this.props.history.push("/Add-Location");
    }

    let BannerImages = this.state.promotion_banner_images;
    localStorage.setItem("Images", JSON.stringify(BannerImages));
    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div className="container">
            <div className="row">
              <BackAndBreadCrmOneParent
                // backLink="/eventlist"
                backLink={localStorage.getItem("Prev")}
                backName="eventlist"
                currentTabName="View-Location"
              />
              <div className="col">
                <h3>{this.state.event_title}</h3>
                <div
                  className="card shadow p-3 mb-5 bg-white rounded"
                  style={{
                    width: "600px",
                    marginTop: "20px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <h5>
                    {" "}
                    Address1:&nbsp;{this.state.event_address}
                    <br />
                  </h5>
                  <hr className="mx-n3" />
                  <h5>
                    {" "}
                    Address2:&nbsp;{this.state.event_address2}
                    <br />
                  </h5>
                  <hr className="mx-n3" />
                  <h5>
                    {" "}
                    State:{this.state.state}
                    <br />
                  </h5>
                  <hr className="mx-n3" />
                  <h5>
                    {" "}
                    City :&nbsp;{this.state.cities[0]}
                    <br />
                  </h5>
                  <hr className="mx-n3" />
                  <h5>
                    {" "}
                    Pincode:&nbsp;{this.state.event_pincode}
                    <br />
                  </h5>
                  <hr className="mx-n3" />
                  <h5>
                    {" "}
                    Event Latitude:{this.state.latitude}
                    <br />
                  </h5>
                  <hr className="mx-n3" />
                  <h5>
                    {" "}
                    Event Longitude:{this.state.longitude}
                    <br />
                  </h5>
                  <hr className="mx-n3" />
                  <div>
                    <Link to="/Edit-Location">
                      <button
                        class="btn btn-success mx-2"
                        style={{ width: "120px", padding: "10px" }}
                      >
                        Edit
                      </button>
                    </Link>
                    <Link to="/Add-Location">
                      <button
                        class="btn btn-success mx-2"
                        style={{ width: "120px", padding: "10px" }}
                      >
                        Add Location
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col">
                <div
                  className="card"
                  style={{
                    width: "600px",
                    marginTop: "20px",
                    paddingBottom: "10px",
                  }}
                >
                  <div className="card header">
                    <h2>Banner Images</h2>
                  </div>
                  <form
                    id="banner image"
                    method="post"
                    encType="multipart/form-data"
                  >
                    <div className="textOnInputs">
                      <div className="col-md-12 mt-3">
                        <label htmlFor="image">
                          <span style={{ color: "#ff0000" }}>*</span>Promotional
                          Campaign Photo
                        </label>

                        {/* <input type="file" accept="image/png, image/gif, image/jpeg" id="image" name="college_id" className="form-control-file"/> */}
                        {/* {this.state. promotion_banner_images ? <img id="image" src={this.state. promotion_banner_images} alt="" width="200px" height="80px" /> : ""} */}
                        <div className="errorMsg">
                          {this.state.errors.promotion_banner_images}
                        </div>
                      </div>

                      {this?.state?.promotion_banner_images?.length === 0
                        ? null
                        : this?.state?.promotion_banner_images?.map((img) => {
                          const imageSrc = img?.type === "IMAGE" ? img.url : img;
                          return (
                            <>
                              <div
                                className="card"
                                style={{ margin: 10, width: 200 }}
                              >
                                <img
                                  src={imageSrc}
                                  key={imageSrc}
                                  className="card-img-top"
                                  alt={img.name}
                                  width="100px"
                                  height="100px"
                                />
                                <div class="card-body">
                                  <button
                                    type="delete"
                                    className="btn btn-danger btn-sm btn-block"
                                    onClick={() =>
                                      this.removeImageURL(img.Id)
                                    }
                                  >
                                    delete
                                  </button>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>

                    <br />
                    <hr className="mx-n3" />

                    {/* <Link onClick={() => localStorage.setItem('EventListData',JSON.stringify(eventlist))} to="/Add-Location"><button type="button" className="btn btn-success btn-sm m-1">Add Location</button></Link> */}

                    <div class="row justify-content-center">
                      <button
                        class="btn btn-default btn-primary"
                        style={{ width: "120px", padding: "10px" }}
                      >
                        {this.state.loader ? <LoaderRounded /> : "Edit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
