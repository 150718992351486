import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { Edit } from "@material-ui/icons";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import candidate_old from "../data/static-candidate";
import { Button, Container, Row, Table, Col, Spinner, Form, } from "react-bootstrap";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";
import ReactPaginate from "react-paginate";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import * as Icon from "react-bootstrap-icons";
import moment from "moment";
class AllUserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilter: "",
      currentFilterBy: "",
      candidate_old: candidate_old,
      candidate: [],
      candidate: [],
      CityData: [],
      selectValue: "all",
      redirect: false,
      phase: "PHASE1",
      currentPage: "",
      counts: "",
      totalPages: "",
      previousLimits: 100,
      renderPageLimit: 20,
      selectedPage: 0,
      filterString: "",
      filterdata: [],
      districtsLocal: JSON.parse(localStorage.getItem("districts")),
      allpage: "",
      limitVal: 10,
      view_count: 0,
      page_selected: 0,
      selected_distict:
        this.props?.location?.state?.param2 ||
        JSON.parse(localStorage.getItem("districts")),
      selected_year: "",
      year: this.props?.location?.state?.param1 || "",
      years: [],
      filterDate: "",
      loading: true,
      loader: false,
    };

    this.applyFiter = this.applyFilter.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.Editcandidate = this.Editcandidate.bind(this);
    this.searchFromList = this.searchFromList.bind(this);
    this.getCollegeWithCity = this.getCollegeWithCity.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.searchList = this.searchList.bind(this);
    this.hanldeRowchange = this.hanldeRowchange.bind(this);
    this.getData = this.getData.bind(this);
    this.getCollegeWithYear = this.getCollegeWithYear.bind(this);
  }

  componentWillMount() {
    setTimeout(() => this.setState({ loading: false }), 1000);
  }

  componentDidMount() {
    // Set initial years
    const currentYear = new Date().getFullYear();
    const initialYears = this.generateYears(2021, currentYear + 1);
    this.setState({ years: initialYears });
  }

  generateYears(start, end) {
    const yearsArray = [];
    for (let year = end; year >= start; year--) {
      yearsArray.push(year);
    }
    return yearsArray;
  }

  handleCompleteYear = () => {
    const currentYear = new Date().getFullYear();
    this.setState((prevState) => ({
      years: [...prevState.years, currentYear + 1],
    }));
  };

  handlePageChange(page) {
    let val = page.selected === 0 ? 0 : page.selected * this.state.limitVal;
    this.setState({
      view_count: val,
      page_selected: page.selected,
    });

    this.getDataWithCity(
      this.state.limitVal,
      val,
      this.state.selected_distict,
      this.state.year,
      this.state.filterDate
    );
  }

  hanldedivchange(event) {
    this.setState({
      limitVal: event,
      selectedPage: 0,
    });
    this.getData(
      event,
      this.state.skip_value,
      this.state.selected_distict,
      this.state.year,
      this.state.filterDate
    );
  }

  hanldeRowchange(row) {
    this.setState({
      limitVal: row,
    });
    this.getDataWithCity(
      row,
      this.state.view_count,
      this.state.selected_distict,
      this.state.year,
      this.state.filterDate
    );
  }

  applyFilter(arr) {
    if (!this.state.currentFilter) {
      return arr;
    }
    let filteron = this.state.currentFilterBy;
    if (filteron === "city") {
      return arr.filter((x) => x.city === this.state.currentFilter);
    } else {
      let a = this.state.currentFilter.toLowerCase();
      var condition = new RegExp(a);
      var result = arr.filter(function (el) {
        return (
          condition.test(el.first_name.toLowerCase()) ||
          condition.test(el.last_name.toLowerCase())
        );
      });

      return result;
    }
  }

  setFilter(e) {
    e.preventDefault();
    this.setState({
      currentFilter: e.target.value,
    });
  }

  UNSAFE_componentWillMount() {
    const currentYear = new Date().getFullYear();
    const initialYears = this.generateYears(2021, currentYear);
    this.setState({ years: initialYears });

    const storedYear = localStorage.getItem("searchedYear");
    const storedDistrict = localStorage.getItem("searchedDistrict");

    // If stored values are available, set them in the state
    if (storedYear || storedDistrict) {
      this.setState({
        year: storedYear,
        selected_distict: storedDistrict,
      });
    } else {
      // If not, use default values
      const defaultDistrict = JSON.parse(localStorage.getItem("districts"));
      this.setState({
        years: this.generateYears(2021, currentYear),
        selected_distict: defaultDistrict,
      });
    }

    // this.getData(this.state.limitVal, this.state.view_count, this.state.selected_distict, this.state.year);
    this.getDataWithCity(
      this.state.limitVal,
      this.state.view_count,
      storedDistrict ? storedDistrict : this.state.selected_distict,
      storedYear ? storedYear : this.state.storedYear,
      this.state.filterDate
    );
    this.showCity();
  }

  showCity() {
    let districts = JSON.parse(localStorage.getItem("districts"));
    if (Array.isArray(districts) && districts[0] !== "*") {
      this.setState({
        CityData: districts,
      });
    } else {
      axios
        .get(process.env.REACT_APP_APIURL + `/address/city/MH`)
        .then((response) => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.setState({
              CityData: response.data,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }

  getData(limitVal, skip_value) {
    this.setState({
      loader: true,
    });
    let currentComponent = this;
    let a = JSON.parse(localStorage.getItem("districts"));
    let hello = this.state.selected_distict === "All" ? a : a;
    axios
      .get(
        process.env.REACT_APP_APIURL +
        `/candidate/user/list/city/` +
        hello +
        `?view_count=${skip_value}` +
        `&limit=${limitVal}`
      )
      .then((res) => {
        if (res.data.data) {
          let con = Math.ceil(
            parseInt(res.data.allCount) / this.state.limitVal
          );

          let responses = res.data.data;
          currentComponent.setState({
            allpage: con,
            candidate: responses,
            filterdata: responses,
            loader: false,
          });
          localStorage.setItem("filteredData", JSON.stringify(responses));
        }
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  getDataWithCity(limitVal, skip_value, city, selectedYear, date) {
    let new_date = date ? moment(date).format("DD-MM-YYYY") : "";
    this.setState({
      loader: true,
    });
    let currentComponent = this;
    const splitdate = new_date.split("-");
    const mDate =
      splitdate[0]?.indexOf(0) == 0
        ? splitdate[0] % 10
        : parseInt(splitdate[0]);

    const mMonth =
      splitdate[1]?.indexOf(0) == 0
        ? splitdate[1] % 10
        : parseInt(splitdate[1]);
    const mYear = parseInt(splitdate[2]);
    let splitvaluedate = `${mDate}-${mMonth}-${mYear}`;
    let subcity = localStorage.getItem("sub_city_name");
    let phases = this.state.phase;
    // let a = city === "All" ? ["*"] : city;

    axios.get(process.env.REACT_APP_APIURL +
      `/candidate/user/list/city/` +
      city +
      `?view_count=${skip_value}` +
      `&limit=${limitVal}` +
      `&year=${selectedYear ? selectedYear : ""}` +
      `&dob=${new_date ? splitvaluedate : ""}`
    )
      .then((res) => {
        if (res.data.data) {
          let con = Math.ceil(
            parseInt(res.data.allCount) / this.state.limitVal
          );

          let responses = res.data.data;
          currentComponent.setState({
            allpage: con,
            candidate: responses,
            filterdata: responses,
            loader: false,
          });
        }
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  Editcandidate(yinID) {
    localStorage.setItem("yin_id", yinID);
    this.setState({
      redirect: true,
    });
  }

  getCollegeWithCity = (event) => {
    const searchedDistrict = event.target.value;
    this.setState({
      selected_distict: searchedDistrict,
    });
    // Store the searched district in localStorage
    localStorage.setItem("searchedDistrict", searchedDistrict);

    this.getDataWithCity(
      this.state.limitVal,
      this.state.view_count,
      searchedDistrict,
      this.state.year,
      this.state.filterDate
    );
  };

  // handle year onChange
  getCollegeWithYear = (event) => {
    const searchedYear = event.target.value;
    this.setState({
      year: searchedYear,
    });
    // Store the searched year in localStorage
    localStorage.setItem("searchedYear", searchedYear);
    this.getDataWithCity(
      this.state.limitVal,
      this.state.view_count,
      this.state.selected_distict,
      searchedYear,
      this.state.filterDate
    );
  };
  
  searchFromList(event) {
    this.setState({
      candidate: event.target.value,
      currentFilterBy: event.target.name,
    });
  }


  searchList(char) {
    let f = this.state.filterdata?.filter(
      (item) =>
        item?.yin_id
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.mobile
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.gender
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.city?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
        item?.college_name
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.first_name
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.last_name
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase())
    );
    this.setState({
      candidate: f,
    });
  }

  handleSearchByMobileNo(e) {
    this.setState({
      loader: true,
    });
    let currentComponent = this;

    axios.get(process.env.REACT_APP_APIURL + "/users/checkuser/" + e.target.value)
      .then((res) => {
        if (res.data.data) {
          let con = Math.ceil(
            parseInt(res.data.allCount) / this.state.limitVal
          );

          let responses = res.data.data;
          currentComponent.setState({
            // allpage: con,
            candidate: responses,
            allpage: con,
            // filterdata: responses,
            loader: false,
          });
        }
      })
      .catch((error) => {
        currentComponent.setState({
          loader: false,
        });
        console.log("Error", error);
      });
  }

  render() {
    const backlink = localStorage.getItem("setbacklink");

    if (this.state.redirect) {
      return (
        <Redirect to={{
          pathname: "/edit/user", state: {
            year: this?.state?.year,
            city: this?.state?.selected_distict === JSON.parse(localStorage.getItem("districts")) ? JSON.parse(localStorage.getItem("districts")) : this?.state?.selected_distict
            // city: this?.state?.selected_distict,
          },
        }}
        />
      );
    }

    if (!localStorage.getItem("mobile")) {
      return <Redirect to="/login" />;
    }

    let candidateList = "";
    if (this.state.candidate.length > 0) {
      candidateList =
        this.state.candidate &&
        this.state.candidate.map((candidates, id) => (
          <tr key={id}>
            <td>{this.state.page_selected * this.state.limitVal + id + 1}</td>
            <td>
              {candidates.first_name} {candidates.last_name}
            </td>
            <td>{candidates.college_name}</td>
            <td className="noselect">{candidates.mobile}</td>
            <td>{candidates.gender}</td>
            <td>{candidates.city}</td>

            <td className="icanostyle text-center">
              <span onClick={() => this.Editcandidate(candidates.yin_id)}>
                <button className="btn btn-outline-info btn-sm me-2">
                  <Edit />
                </button>
              </span>
            </td>
          </tr>
        ));
    } else {
      candidateList = (
        <tr>
          <td colSpan="6" className="text-center">
            No Data Found....
          </td>
        </tr>
      );
    }

    let cities;
    const CityData = {};
    if (this.state.districtsLocal[0] !== "*") {
      cities = this.state.CityData.map((e, key) => {
        return (
          <option key={key} value={e}>
            {e}
          </option>
        );
      });
    } else {
      cities = this.state.CityData?.map((e, key) => {
        return (
          <option key={key} value={e.name}>
            {e.name}
          </option>
        );
      });
    }

    // get users district data
    const getDistData = localStorage.getItem("districts");
    const districtData = JSON.parse(getDistData);
    const getNewDistData = districtData?.map((itm, index) => {
      return itm;
    });

    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />
            <div className="main">
              <div className="topbar">
                <Navbar title="All YIN Member List" />
              </div>

              <div className="container mt-4">
                <Row className="mx-3" md={12}>
                  <Col md={6}>
                    <Row md={12}>
                      <Col md={6} className="mb-2 mb-sm-0">
                        <input
                          type="text"
                          placeholder="Search here"
                          name="search"
                          value={this.state.filterString}
                          onChange={(e) => {
                            this.setState({ filterString: e.target.value });
                            this.searchList(e.target.value);
                          }}
                          className="form-control"
                        ></input>
                      </Col>

                      <Col md={6} className="mb-2 mb-sm-0">
                        <input
                          type="text"
                          placeholder="Search by Mobile No."
                          // name="search"
                          // value={this.state.filterString}
                          onChange={(e) => {
                            this.handleSearchByMobileNo(e);
                          }}
                          className="form-control"
                        ></input>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={6}>
                    <Row md={12} className="justify-content-end">
                      <Col md={4} className="mb-2 mb-sm-0">
                        <select
                          name="year"
                          className="btn dropdown-toggle w-100"
                          style={{ backgroundColor: "#003147", color: "white" }}
                          onChange={this.getCollegeWithYear}
                          value={this.state.year}
                        >
                          <option value="">All Years</option>
                          {this.state.years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </Col>

                      <Col md={4} className="mb-2 mb-sm-0">
                        <Form.Select
                          type="button"
                          name="city"
                          onChange={this.getCollegeWithCity}
                          id="district"
                          value={this.state.selected_distict}
                          style={{
                            backgroundColor: "#003147",
                            color: "white",
                            WebkitAppearance: "none",
                            appearance: "none",
                            paddingRight: "2rem",
                            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="white"><path d="M8 12.28l6.364-6.364a1 1 0 0 0-1.414-1.414L8 9.455 2.636 4.636A1 1 0 0 0 1.222 6.05L8 12.28z"/></svg>')`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "right center",
                          }}>
                          <option value={getNewDistData}>All</option>
                          {cities}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row md={12} className="mx-3 mt-4 mb-4">
                  <Col md={6} className="d-flex">
                    <Col md={4} className="mb-2 mb-sm-0">
                      <Form.Control value={this.state.filterdate}
                        type="date"
                        onChange={(date) => {
                          this.setState({
                            filterdate: date.target.value,
                          });
                          this.getData(
                            this.state.selected_distict,
                            this.state.phase,
                            this.state.year,
                            this.state.skip_value,
                            this.state.limitVal,
                            date.target.value
                          );
                        }} />
                    </Col>

                    <Col md={2} className="mb-2 mb-sm-0 ms-2">
                      <button
                        className="btn btn-success btn-md"
                        onClick={() => {
                          this.setState({
                            filterdate: "",
                          });
                          this.getData(
                            this.state.selected_distict,
                            this.state.phase,
                            this.state.year,
                            this.state.skip_value,
                            this.state.limitVal,
                            null
                          );
                        }}
                      >
                        <Icon.ArrowClockwise size={18} className="me-2" />
                        Clear
                      </button>
                    </Col>
                  </Col>

                  <Col md={6} className="mb-2 mb-sm-0 justify-content-end">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span>Records Per Page:</span>
                      <Form.Control
                        className="w-50 ms-1"
                        id="pageselect"
                        as="select"
                        onChange={(event) =>
                          this.hanldedivchange(event.target.value)
                        }
                      >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="300">300</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </Form.Control>
                    </div>
                  </Col>
                </Row>

                <div
                  className="container"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div
                        className="table-responsive-sm"
                        style={{ maxHeight: 1600, overflowY: "scroll" }}
                      >
                        <table className="table table-bordered table-responsive table-hover">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Name</th>
                              <th>College</th>
                              <th>Mobile</th>
                              <th>Gender</th>
                              <th>City</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.loader ? (
                              <tr>
                                <td colSpan={7}>
                                  <LoaderRounded />
                                </td>
                              </tr>
                            ) : (
                              candidateList
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  <ReactPaginate
                    nextLabel="Next"
                    previousLabel="Previous"
                    pageCount={this.state.allpage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    containerClassName="pagination"
                    activeClassName="btn btn-primary"
                    pageClassName="btn"
                    previousClassName="btn"
                    nextClassName="btn"
                    onPageChange={this.handlePageChange}
                  />
                </div>

                <button className="reaload btn  btn-success btn-sm mb-3 mt-3" onClick={() => window.location.reload(true)}>
                  <Icon.ArrowClockwise size={18} className="me-2" />
                  Refresh
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default AllUserList;
