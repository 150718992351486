import React, { Component } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import S3 from "react-aws-s3";
import Compressor from "compressorjs";
import moment from "moment";
import { Table } from "react-bootstrap";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";
import { Redirect } from "react-router-dom";
import {
  MdOutlineSubtitles,
  MdOutlineDescription,
  MdDateRange,
  MdOutlineCategory,
} from "react-icons/md";
import { AiOutlineLink } from "react-icons/ai";
import { Delete } from "@material-ui/icons";
import { responsiveMap } from "antd/lib/_util/responsiveObserve";
import { uploadImageFromBackend } from "../Utils/uploadImage";

export default class ViewAplify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      college_code: "",
      diaplay_name: "",
      description: "",
      forum_full_description: "",
      forum_images: [],
      forum_type: "",
      is_published: "",
      forum_created_by: "",
      MemberData: [],
      memberlist: "",
      member: "",
      redirect: false,
      errors: {},
      loader: false,
    };
    this.uploadImageToS3 = this.uploadImageToS3.bind(this);

    // this.deleteAmplifierData = this.deleteAmplifierData.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.getForumData = this.getForumData.bind(this);
  }
  componentDidMount() {
    this.getForumData();
    // this.deleteAmplifierData();
    // this.handleCallupdate();
  }
  handleFormChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  addNewImage(newImage) {
    let img_arr = this.state.forum_images;
    img_arr.push(newImage);
    this.setState({
      forum_images: img_arr,
    });
  }

  removeImageURL(id) {
    let img_arr = this.state.forum_images;
    img_arr = img_arr.filter((img) => img.id !== id);
    this.setState({
      forum_images: img_arr,
    });
  }

  uploadImageToS3(event) {
    const image = event.target.files[0]; // Extract the selected image
    if (!image) {
      console.error("No file selected.");
      return;
    }

    // Validate file type
    if (image.type === "image/png" || image.type === "image/jpeg") {
      // Compress the image
      new Compressor(image, {
        quality: 0.6, // Adjust quality for compression
        success: async (compressedResult) => {
          try {
            // Generate a new file name without extension
            const newFileName = image.name.replace(/\..+$/, "");

            // Call the backend upload function
            const imageURL = await uploadImageFromBackend(newFileName, image.type, compressedResult);

            if (imageURL) {
              // Add the uploaded image details to the state
              this.addNewImage({
                type: "IMAGE",
                url: imageURL,
                name: newFileName,
                id: Math.floor(Math.random() * 9999999999),
              });
            } else {
              console.error("Image upload failed.");
            }
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        },
        error: (err) => {
          console.error("Compression error:", err.message);
        },
      });
    } else {
      console.error("Invalid file type. Please upload a PNG or JPEG image.");
    }
  }


  getForumData() {
    let forum_id;
    let currentComponent = this;
    if (this.props.location.data) {
      localStorage.setItem("forum_id", this.props.location.data.forum_id);
      forum_id = this.props.location.data.forum_id;
    } else {
      forum_id = localStorage.getItem("forum_id");
    }
    const forumid = {
      forum_id: localStorage.getItem("forum_id"),
    };
    axios
      .get(
        `https://yin-talk-api.foxberry.link/v1/forum/get/forum/info/${forum_id}`
      )
      .then((res) => {
        let responses = res.data;

        console.log("responses", responses);

        currentComponent.setState({
          // redirect:true,
          ...responses[0],
        });
      });
  }

  // getCommentData() {
  //   // let amplifier_id;
  //   let currentComponent = this;
  //   // if (this.props.location.data) {
  //   //   localStorage.setItem("amplifier_id", this.props.location.data._id);
  //   //   amplifier_id = this.props.location.data._id;
  //   // } else {
  //   //   amplifier_id = localStorage.getItem("amplifier_id");
  //   // }

  //    const  entityid =  localStorage.getItem('amplifier_id')


  //   axios.get(`https://yin-api.foxberry.link/v1/comment/get/all/comments/`+entityid).then((res) => {
  //     let responses = res.data;

  //       if(responses?.message !== "No Message Found"){
  //           currentComponent.setState({
  //               comment: responses.data
  //             });
  //       }else{
  //           this.setState({
  //               msg:'No Message Found'
  //           })
  //       }
  //   });
  // }

  render() {
    // if (this.state.redirect) {
    //   return <Redirect to="/ForumList" />;
    // }
    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div></div>
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink="/ForumList"
              backName="ForumList"
              currentTabName="View Forum Details"
            />

            <div className="row mt-4">
              <div
                style={{
                  heigh: "800vh",
                  overflow: "hidden",
                }}
                className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto "
              >
                <div className="card rounded shadow shadow-sm ">
                  <div className="card-header">
                    <h3 className="mb-0 center">View Forum</h3>
                  </div>
                  <div className="card-body">
                    <form>
                      {/* <textarea className="form-control" name="event_full_description_mar"  onChange={this.handleFormChange} id="FullDescription" placeholder='येथे लिहा' rows="3" required></textarea> */}

                      <Table style={{ fontSize: 18, marginTop: 20 }}>
                        <tbody style={{ width: "190px" }}>
                          <tr>
                            <td
                              style={{
                                fontWeight: "bold",
                                display: "flex",
                                color: "#003147",
                              }}
                            >
                              {" "}
                              <span></span>Forum Title
                            </td>
                            <td>{this.state.name}</td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontWeight: "bold",
                                display: "flex",
                                color: "#003147",
                                alignItems: "center",
                                height: "80px",
                              }}
                            >
                              <span></span>Forum Display Name{" "}
                            </td>
                            <td>{this.state.diaplay_name}</td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontWeight: "bold",
                                display: "flex",
                                color: "#003147",
                              }}
                            >
                              <span></span>
                              College Code{" "}
                            </td>
                            <td>{this.state.college_code}</td>
                          </tr>
                          <tr>
                            <td
                              style={{ fontWeight: "bold", color: "#003147" }}
                            >
                              <span></span>Forum Description
                            </td>
                            <td>{this.state.forum_full_description}</td>
                          </tr>
                          <tr>
                            <td
                              style={{ fontWeight: "bold", color: "#003147" }}
                            >
                              <span></span>Forum Type
                            </td>
                            <td>{this.state.forum_type}</td>
                          </tr>
                          <tr>
                            <td
                              style={{ fontWeight: "bold", color: "#003147" }}
                            >
                              <span></span>Is Published
                            </td>
                            <td>{this.state.is_published}</td>
                          </tr>
                          <tr>
                            <td
                              style={{ fontWeight: "bold", color: "#003147" }}
                            >
                              <span></span>Forum Created Person Name
                            </td>
                            <td>{this.state.forum_created_by}</td>
                          </tr>

                          <tr>
                            <td
                              style={{ fontWeight: "bold", color: "#003147" }}
                            >
                              <span></span> Forum Images
                            </td>
                            <td>
                              {this.state.forum_images.length === 0
                                ? null
                                : this.state.forum_images.map((img, index) => {
                                  // for showing image hanlder condition
                                  const imageSrc = img?.type === "IMAGE" ? img.url : img;
                                  return (
                                    <>
                                      <div
                                        className="card"
                                        style={{ margin: 10, width: 200 }}
                                      >
                                        <img
                                          src={imageSrc}
                                          className="card-img-top"
                                          alt={img.name || 'Image'}
                                          width="100px"
                                          height="100px"
                                        />
                                      </div>
                                      {/* <img style={{ margin: 10 }} width="100px" height="100px" key={img.url} src={img.url} alt={img.name} />
                                                                    <button type="delete" id="btnSubmitSignup" className="btn btn-danger btn-sm btn-block" onClick={() => this.removeImageURL(img.id)} >delete</button> */}
                                    </>
                                  );
                                })}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
