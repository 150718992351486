export async function uploadImageFromBackend(newFileName, fileType, imageData) {
    try {
        let url = `${process.env.REACT_APP_APIURL}/upload/photo`;
        let formData = new FormData();
        formData.append('image', imageData); // Add the file
        formData.append('fileName', newFileName); // Add additional parameters
        formData.append('fileType', fileType);

        // Send the file and data to the backend
        const uploadResponse = await fetch(url, {
            method: 'POST',
            body: formData, // FormData handles multipart encoding automatically
        });

        if (uploadResponse.ok) {
            const responseData = await uploadResponse.json();
            return responseData.url;
        } else {
            console.error('File upload failed');
            return "File upload failed";
        }
    } catch (error) {
        console.log("----error*****", error);
        return error;
    }
}
