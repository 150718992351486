import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import S3 from "react-aws-s3";
import Compressor from "compressorjs";
import moment from "moment";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import "../Assets/css/Dashboard.css";
import Select from "react-select";
import LoaderRounded from "./LoaderRounded";
import { uploadImageFromBackend } from "../Utils/uploadImage";

export default class Position extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      imageURL: [],
      category: "",
      eventLink: "",
      redirect: false,
      errors: {},
      loader: false,
      amplifierDate: "",
      dropDownOptCategoryList: [],
    };
    this.uploadImageToS3 = this.uploadImageToS3.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  handleFormChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleCategoryChange(event) {
    this.setState({
      [event.name]: event.value,
    });
  }

  UNSAFE_componentWillMount() {
    this.getCategory();
  }

  getCategory = async () => {
    const API = await axios.get(
      process.env.REACT_APP_APIURL + `/Contents/get/category/all`
    );
    if (Array.isArray(API.data) && API.data.length > 0) {
      const serverResponse = API.data;

      const dropDownValue = serverResponse.map((response) => ({
        value: response.title,
        label: response.title,
        name: "category",
      }));

      this.setState({
        dropDownOptCategoryList: dropDownValue,
      });
    }
  };

  onChangeForCategoryListDropDown(event) {
    this.setState({
      id: event.value,
      category: event.label,
      isSelect: true,
    });

    localStorage.setItem("id", event.value);
    localStorage.setItem("category", event.label);
  }

  addNewImage(newImage) {
    let img_arr = this.state.imageURL;

    img_arr.push(newImage.imageURL);
    this.setState({
      imageURL: img_arr,
    });
  }

  // removeImageURL(Img) {
  //   let img_arr = this.state.imageURL;

  //   img_arr = img_arr.filter((img) => img != Img);
  //   this.setState({
  //     imageURL: img_arr,
  //   });
  // }

  removeImageURL(Img) {
    let img_arr = this.state.imageURL;

    img_arr = img_arr.filter((img) => img !== Img);
    this.setState({
      imageURL: img_arr,
    });
  }

  handleImageRemoval = (img) => {
    this.removeImageURL(img);

    // Reset file input value
    if (this.fileInputRef && this.fileInputRef.current) {
      this.fileInputRef.current.value = "";
    }
  };

  removePdfURL(id) {
    let pdf = this.state.event_documents;
    pdf = pdf.filter((pdf) => pdf.id !== id);
    this.setState({
      event_documents: pdf,
    });
  }

  // for upload image
  uploadImageToS3(event) {
    const image = event.target.files[0];
    if (!image) return;

    if (image.type === "image/png" || image.type === "image/jpeg") {
      new Compressor(image, {
        quality: 0.6,
        success: async (compressedResult) => {
          try {
            let newFileName = image.name.replace(/\..+$/, "");
            let fileType = image.type;

            const imageURL = await uploadImageFromBackend(
              newFileName,
              fileType,
              compressedResult
            );

            if (imageURL) {
              const newImageURL = {
                imageURL,
              };

              this.addNewImage(newImageURL);
            } else {
              console.error("Image upload failed.");
            }
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        },
        error: (err) => {
          console.error("Compression error:", err.message);
        },
      });
    } else {
      console.error("Invalid file type. Please upload a PNG or JPEG image.");
    }
  }


  handleSubmit(event) {
    event.preventDefault();

    const errors = this.validateForm();

    if (Object.keys(errors).length === 0) {
      // Form validation succeeded, proceed with form submission
      // ...
      this.setState({ loader: true });

      axios
        .post(
          process.env.REACT_APP_APIURL + `/amplifier/insert`,
          {
            title: this.state.title,
            description: this.state.description,
            imageURL: this.state.imageURL ? this.state.imageURL : "",
            category: this.state.category,
            eventLink: this.state.eventLink,
            amplifierDate: this.state.amplifierDate,
          }
          //{ withCredentials: true }
        )
        .then((response) => {
          if (response.data) {
            alert("Amplifier added successfully");
            this.setState({
              redirect: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loader: false });
        });
    } else {
      // Form validation failed, focus on the first input field with an error message
      const firstErrorField = document.querySelector(
        `[name="${Object.keys(errors)[0]}"]`
      );
      if (firstErrorField && firstErrorField.focus) {
        firstErrorField.focus();
      }

      this.setState({ errors });
    }
  }


  validateForm() {
    const { title, description, category, eventLink, amplifierDate, imageURL } =
      this.state;
    const errors = {};
    let arr = [];

    if (title.trim() === "") {
      errors.title = "Title is required.";
      arr.push("Amplify Name");
    }

    if (description.trim() === "") {
      errors.description = "Description is required.";
      arr.push("description");
    }

    if (category === "") {
      errors.category = "Category is required.";
      arr.push("category");
    }

    if (eventLink.trim() === "") {
      errors.eventLink = "Event link is required.";
      arr.push("eventlink");
    }

    if (amplifierDate.trim() === "") {
      errors.amplifierDate = "Amplifier date is required.";
      arr.push("Amplify date");
    }
    if (imageURL.length <= 0) {
      errors.imageURL = "Image is required.";
      arr.push("imageURL");
    }

    // Focus on the first input field with an error message
    const firstErrorField = document.querySelector(`[name="${arr[0]}"]`);
    if (firstErrorField && firstErrorField.focus) {
      firstErrorField.focus();
    }

    return errors;
  }


  render() {
    const { redirect, errors } = this.state;

    if (redirect) {
      return <Redirect to="/AmplifyList" />;
    }
    return (
      <div>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div></div>
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink="/AmplifyList"
              backName="AmplifyList"
              currentTabName="Add Amplify"
            />
            <div className="row mt-4">
              <div
                style={{
                  heigh: "800vh",
                  overflow: "hidden",
                }}
                className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto "
              >
                <div className="card rounded shadow shadow-sm ">
                  <div className="card-header">
                    <h3 className="mb-0 center">Add Amplify</h3>
                  </div>
                  <div className="card-body">
                    <form
                      id="candidate"
                      method="post"
                      onSubmit={(e) => this.handleSubmit(e)}
                      encType="multipart/form-data"
                    >
                      <div className="form-group">
                        <label htmlFor="Amplify Name" className="control-label">
                          Amplify Title
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.title ? " is-invalid" : ""
                            }`}
                          id="Amplify Name"
                          name="title"
                          onChange={this.handleFormChange}
                          value={this.state.title}
                          placeholder="Type here"
                        />
                        {errors.title && (
                          <div className="errorMsg">{errors.title}</div>
                        )}
                      </div>

                      <div className="form-group mt-2">
                        <label htmlFor="description" className="control-label">
                          {" "}
                          Amplify Description
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.description ? " is-invalid" : ""
                            }`}
                          id="description"
                          name="description"
                          onChange={this.handleFormChange}
                          value={this.state.description}
                          placeholder="Type here"
                        />
                        {errors.description && (
                          <div className="errorMsg">{errors.description}</div>
                        )}
                      </div>

                      <div className=" row col-12 mt-2" style={{ Padding: "0px" }}>
                        <div className="form-group col-6">
                          <label
                            htmlFor="Amplify date"
                            className="control-label"
                          >
                            Amplifier Date
                            <span style={{ color: "#ff0000" }}>*</span>
                          </label>
                          <input
                            type="date"
                            className={`form-control ${errors.amplifierDate ? " is-invalid" : ""
                              }`}
                            id="Amplify date"
                            name="amplifierDate"
                            onChange={this.handleFormChange}
                            value={moment(this.state.amplifierDate).format(
                              "yyyy-MM-DD"
                            )}
                            placeholder="Type here"
                          />
                          {errors.amplifierDate && (
                            <div className="errorMsg">
                              {errors.amplifierDate}
                            </div>
                          )}
                        </div>

                        <div className="form-group col-6">
                          <label
                            htmlFor="Amplify category"
                            className="control-label"
                          >
                            {" "}
                            Category<span style={{ color: "#ff0000" }}>*</span>
                          </label>
                          <Select
                            className="basic-multi-select"
                            classNamePrefix="react-select"
                            isSearchable={true}
                            placeholder="Select category"
                            id="category"
                            name="category"
                            // name={this.state.dropDownOptCategoryList.title}
                            options={this.state.dropDownOptCategoryList}
                            onChange={this.handleCategoryChange}
                          />
                          {errors.category && (
                            <div className="errorMsg">{errors.category}</div>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="eventLink" className="control-label">
                          {" "}
                          Event Link<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control${errors.eventLink ? " is-invalid" : ""
                            }`}
                          id="eventLink"
                          name="eventLink"
                          value={this.state.eventLink}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        />
                        {errors.eventLink && (
                          <div className="errorMsg">{errors.eventLink}</div>
                        )}
                      </div>

                      <div className="textOnInputs">
                        <div className="col-md-12 mt-2">
                          <label htmlFor="imageURL">
                            Image URL
                            <span style={{ color: "#ff0000" }}>*</span>
                          </label>
                          <input
                            type="file"
                            className={`form-control${errors.imageURL ? " is-invalid" : ""}`}
                            id="imageURL"
                            name="college_id"
                            accept="image/*"
                            onChange={this.uploadImageToS3}
                            key={this.state.imageURL.length}
                          // ref={this.fileInputRef}
                          />
                          {this.state.imageURL.length === 0
                            ? null
                            : this.state.imageURL.map((img) => {
                              return (
                                <>
                                  <div
                                    className="card"
                                    style={{ margin: 10, width: 200 }}
                                  >
                                    <img
                                      src={img}
                                      key={img}
                                      className="card-img-top"
                                      alt={"no image"}
                                      width="100px"
                                      height="100px"
                                    />

                                    <div class="card-body">
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm btn-block"
                                        onClick={() => this.handleImageRemoval(img)}
                                      >
                                        delete
                                      </button>
                                    </div>

                                    {/* <div class="card-body">
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-sm btn-block"
                                          onClick={() =>
                                            this.removeImageURL(img)
                                          }
                                        >
                                          delete
                                        </button>
                                      </div> */}
                                  </div>
                                  {/* <img style={{ margin: 10 }} width="100px" height="100px" key={img.url} src={img.url} alt={img.name} />
                                                                    <button type="delete" id="btnSubmitSignup" className="btn btn-danger btn-sm btn-block" onClick={() => this.removeImageURL(img.id)} >delete</button> */}
                                </>
                              );
                            })}
                          {errors.imageURL && (
                            <div className="errorMsg">{errors.imageURL}</div>
                          )}
                          {/*errors.imageURL && <div className="errorMsg">{errors.imageURL}</div>*/}
                        </div>
                        {/*
                                                        this.state.imageURL.length === 0 ? null : (
                                                            this.state.imageURL.map(img => {
                                                                return (
                                                                    <>
                                                                        <div className="card" style={{ margin: 10, width: 200 }} >
                                                                            <img src={img} key={img} className="card-img-top" alt={img} width="100px" height="100px" />
                                                                            <div class="card-body">
                                                                                <button type="delete" id="btnSubmitSignup" className="btn btn-danger btn-sm btn-block" onClick={() => this.removeImageURL(img.id)} >delete</button>
                                                                            </div>

                                                                        </div>
                                                                        {/* <img style={{ margin: 10 }} width="100px" height="100px" key={img.url} src={img.url} alt={img.name} />
                                                                        <button type="delete" id="btnSubmitSignup" className="btn btn-danger btn-sm btn-block" onClick={() => this.removeImageURL(img.id)} >delete</button> //}
                                                                    </>
                                                                );
                                                            })
                                                        )*/}
                      </div>

                      <div className="row">
                        <div className=" col-12 col-md-12 d-flex justify-content-center align-items-center">
                          <button
                            type="submit"
                            id="btnSubmitSignup"
                            onClick={this.handleSubmit}
                            className="btn btn-dark btn-lg btn-block mt-4 m-auto"
                          >
                            {this.state.loader ? <LoaderRounded /> : "Add Amplify"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
